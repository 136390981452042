.container {
    border-radius: 4px !important;
    cursor: pointer !important;
    background-color: #efefef !important;
    min-height: 120px !important;
    transition: height 0.2s ease !important;
}

.initial_type_default {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    margin: 10px 0 !important;

    .iconDescription {
        font-size: 50px !important;
        color: #9c9999 !important;
    }

    .type_default {
        display: flex !important;
        color: #9c9999 !important;
        justify-content: center !important;
        align-items: center !important;
        font-size: 1.2rem !important;
    }

    .type_default_length {
        display: flex !important;
        color: #9c9999 !important;
        justify-content: center !important;
        align-items: center !important;
        font-size: 0.8rem !important;
        font-weight: bold !important;
    }

    .type_default_type {
        display: flex !important;
        color: #9c9999 !important;
        justify-content: center !important;
        align-items: center !important;
        font-size: 0.8rem !important;
        font-weight: bold !important;
        border: 1px solid #9c9999 !important;
        border-radius: 4px !important;
        padding: 4px !important;
        text-transform: uppercase !important;
    }
}

.initial_type_error {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    margin: 10px 0 !important;

    .iconClose {
        font-size: 50px !important;
        color: #e57878 !important;
    }

    .type_error {
        display: flex !important;
        color: #e57878 !important;
        justify-content: center !important;
        align-items: center !important;
        font-size: 1.2rem !important;
    }

    .type_error_length {
        display: flex !important;
        color: #e57878 !important;
        justify-content: center !important;
        align-items: center !important;
        font-size: 0.8rem !important;
        font-weight: bold !important;
    }

    .type_error_type {
        display: flex !important;
        color: #e57878 !important;
        justify-content: center !important;
        align-items: center !important;
        font-size: 0.8rem !important;
        font-weight: bold !important;
        border: 1px solid #e57878 !important;
        border-radius: 4px !important;
        padding: 4px !important;
        text-transform: uppercase !important;
    }
}

.initial_type_success {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    margin: 10px 0 !important;

    .iconGetApp {
        font-size: 50px !important;
        color: #25ac97 !important;
    }

    .type_success {
        display: flex !important;
        color: #25ac97 !important;
        justify-content: center !important;
        align-items: center !important;
        font-size: 1.2rem !important;
    }

    .type_success_length {
        display: flex !important;
        color: #25ac97 !important;
        justify-content: center !important;
        align-items: center !important;
        font-size: 0.8rem !important;
        font-weight: bold !important;
    }

    .type_success_type {
        display: flex !important;
        color: #25ac97 !important;
        justify-content: center !important;
        align-items: center !important;
        font-size: 0.8rem !important;
        font-weight: bold !important;
        border: 1px solid #25ac97 !important;
        border-radius: 4px !important;
        padding: 4px !important;
        text-transform: uppercase !important;
    }
}

@media screen and (max-width: 840px) {
    .initial_type_default {
        .iconDescription {
            font-size: 36px !important;
        }
    
        .type_default {
            font-size: 0.9rem !important;
        }
    
        .type_default_length {
            font-size: 0.7rem !important;
        }
    
        .type_default_type {
            font-size: 0.7rem !important;
        }
    }
}