.dialog_title_auth_my_library {
    background: var(--red-primary) !important;

    :first-child {
        color: #fff !important;
        text-align: center !important;
        font-size: 1.2rem !important;
        font-weight: 500 !important;
    }
}

.dialog_title_auth_my_library.fal {
    background: var(--blue-primary) !important;
}

.area_btn_confirm {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
    padding: 0.8rem 0 !important;
}

.alert_for_popup {
    border-top: 2px double #cbcbcb !important;
}

.info_confirmation {
    display: flex !important;
    align-items: center !important;
    gap: 0.4rem !important;

    font-size: 1.2rem !important;
    font-weight: 500 !important;
    color: #3a3b3a !important;
    margin: 0.8rem 0 !important;

    &.sub_info {
        font-size: 1rem !important;
    }

    > .icon_error {
        color: #b50000 !important;
    }
}

.gif_hability_popup {
    width: 100% !important;
    height: 380px !important;
    border-radius: 4px !important;
}

@media (max-width: 840px) {
    .gif_hability_popup {
        height: 12rem !important;
    }
}
