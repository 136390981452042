.banner {
	// background-color: #BD0E0E;
	min-height: 130vh;
	padding-bottom: 5vh;
	// border-radius: 4px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

	//&:hover {
	//    cursor: pointer;
	//    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
	//}
}

.banner-container {
	position: relative;
	display: flex;
	flex-direction: column;

	justify-content: space-between;
}

.name-container {
	position: relative;
	bottom: 0%;
	left: 0%;
	right: 0%;
	margin-top: 40vh;
	transform: rotate(-90deg);

	-ms-transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);

	white-space: nowrap;
}

@media screen and(max-width: 960px) {
	.banner {
		display: none;
	}
}

a {
	.logo_banner {
		width: 120px;
	}
}
