/* Estilização da scrollbar no Firefox */
// .containerOportunidades {
//     scrollbar-width: thin !important;
//     scrollbar-color: #A8A8A8 transparent !important;
// }

// /* Estilização da scrollbar nos navegadores Chrome, Edge e Safari */
// .containerOportunidades::-webkit-scrollbar {
//     width: 8px !important;
// }

// .containerOportunidades::-webkit-scrollbar-track {
//     background-color: transparent !important;
//     margin: 10px !important;
// }

// .containerOportunidades::-webkit-scrollbar-thumb {
//     background-color: #A8A8A8; 
//     border-radius: 20px;
// }

.container-gamificacao {
    font-family: "Lato";
    background-color: #D3D3D3 !important;

    .gamificacao-content {
        position: relative;
        min-width: 90%;
        max-width: 90%;
        background-color: #FFFFFF !important;
        margin: 30px auto;
        padding: 30px 0;
        border: 1px solid rgba(0, 0, 0, .1);
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
        // border: 1px solid black;

        .card-gamificacao {
            position: relative;
            left: -25px;
            width: 490px;
            height: 180px;
            display: flex;
            flex-direction: row;
            z-index: 2 !important;
            justify-content: center;
            gap: 0px 150px;
            margin-top: 90px;
            padding: 20px;
            border-radius: 14px;
            border: 1px solid rgba(0, 0, 0, .1);
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
            background-color: #FFFFFF !important;

            .dados-perfil {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .foto-perfil-gamificacao {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 5rem !important;
                    height: 5rem !important;
                    margin-bottom: 5px;
                    border-radius: 50%;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

                    .MuiTypography-subtitle1 {
                        font-size: 32px !important;
                    }
                }

                div {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;

                    span:first-child {
                        font-weight: bold !important;
                    }
                }
            }

            .links-gamificacao {
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: 16px;

                ul {
                    text-decoration: none;
                    list-style: none;
                    display: flex;
                    flex-direction: column;

                    li {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-bottom: 8px;
                        color: black;
                        cursor: pointer;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        span {
                            display: inline-block;
                            margin-right: 10px;
                        }

                        a {
                            text-decoration: none;
                            color: black;
                        }
                    }
                }

            }
        }

        .grafico-gamificacao {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            width: auto;
            position: absolute;
            top: 50px;
            right: 70px;

            img {
                width: 150px;
            }

            .grafico {
                .pontuacao-total-gamificacao {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    top: 139px;
                    right: 115px;
                    min-width: 70.5px;
                    max-width: 70.5px;
                    min-height: 69.2px;
                    max-height: 69.2px;
                    font-size: 16px;

                    span {
                        font-size: 40px !important;
                        font-weight: bold;
                    }
                }
            }
        }

        .container-icone {
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            z-index: 1 !important;
            top: 142px;
            min-width: 100% !important;
            max-width: 100% !important;

            .icone-desafio,
            .icone-ranking {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                span {
                    font-size: 150px !important;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                &::after {
                    content: "";
                    width: 70px;
                    height: 5px;
                    border-radius: 5px;
                    background-color: #0000008A;
                }
            }
        }

        .modulos-gamificacao {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            grid-template-rows: repeat(2, auto);
            gap: 20px 0px;
            padding: 90px 30px;

            .gamificacao-modulo:nth-child(1) {
                grid-column-start: 1;
                grid-column-end: 7;
                grid-row: 1;
            }

            .gamificacao-modulo:nth-child(2) {
                grid-column-start: 7;
                grid-column-end: -1;
                grid-row: 1;
            }

            .gamificacao-modulo:nth-child(3) {
                grid-column-start: 1;
                grid-column-end: -1;
                grid-row: 2;
            }

            .gamificacao-modulo {

                h3 {
                    font-weight: bold !important;
                    margin-bottom: 10px;
                }

                .gamificacao-lista {
                    ul {
                        list-style: none;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 10px 10px;

                        li {
                            display: flex;
                            flex-direction: row;
                            padding: 10px 10px 10px 0;
                            min-width: 200px !important;
                            max-width: 200px !important;

                            .cor-modulo {
                                min-width: 12px;
                                max-width: 12px;
                                height: 50px;
                            }

                            .pontuacao-label {
                                display: flex;
                                flex-direction: column;
                                margin-left: 10px;
                                font-size: 16px !important;

                                .pontuacao {
                                    font-size: 24px;
                                    font-weight: bolder;
                                }

                                .label-score {
                                    display: inline-block;
                                    min-width: auto !important;
                                    max-width: auto !important;
                                }
                            }
                        }
                    }
                }
            }
        }

        .desafios-gamificacao,
        .ranking-gamificacao {
            min-width: 100%;
            max-width: 100%;
            padding: 90px 30px;
        }

        .desafios-gamificacao {

            ul {
                list-style: none;
                display: flex;
                flex-direction: column;
                row-gap: 20px;
                // border: 1px solid black !important;

                li {
                    display: flex;
                    position: relative;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-start;
                    min-height: auto !important;
                    max-height: auto !important;
                    // border: 1px solid black !important;

                    .imagem-desafio {
                        min-width: 8%;
                        max-width: 8%;
                        min-height: 100% !important;
                        max-height: 100% !important;

                        img {
                            display: inline-block;
                            min-width: 70% !important;
                            max-width: 70% !important;
                            min-height: 70% !important;
                            max-height: 70% !important;
                        }
                    }

                    .conteudo-desafio {
                        min-width: calc(100% - 16%);
                        max-width: calc(100% - 16%);
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        padding-left: 20px;
                    }

                    .progresso-desafio {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        min-height: 100% !important;
                        max-height: 100% !important;
                        min-width: 8% !important;
                        max-width: 8% !important;
                    }

                    .lottie-desafio {
                        min-height: 100% !important;
                        max-height: 100% !important;
                        min-width: 8%;
                        max-width: 8%;

                        .lottie-conquista {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                        }

                    }

                    .grafico-desafio {
                        min-width: 8%;
                        max-width: 8%;
                        min-height: 100% !important;
                        max-height: 100% !important;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .grafico {
                            min-width: 70% !important;
                            max-width: 70% !important;
                            min-height: 70% !important;
                            max-height: 70% !important;

                            canvas {
                                padding: 0 !important;
                                min-height: auto !important;
                                max-height: auto !important;
                                // border: 1px solid black;
                            }
                        }

                        .progresso-desafio {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                            min-width: 40px;
                            max-width: 40px;
                            position: absolute;
                            top: 53%;
                            left: 50%;
                            margin-right: -50%;
                            transform: translate(-50%, -50%);
                        }

                        span {
                            font-size: .8em;
                            color: #CB1E48;
                        }
                    }
                }
            }
        }

        .ranking-gamificacao {

            .navegacao-tipo-ranking {
                width: 450px;
                height: auto !important;
                margin: 0 auto 90px auto;
                padding: 10px !important;
                border-radius: 14px !important;
                border: 1px solid rgba(0, 0, 0, 0.15);

                span {
                    font-size: 16px !important;
                }

                span.icone-tipo-ranking {
                    font-size: 40px !important;
                }
            }

        }
    }
}

@media screen and (max-width: 1280px) {

    .container-gamificacao {
        background-color: #FFFFFF !important;

        .gamificacao-content {
            position: static;
            min-width: 100%;
            max-width: 100%;
            background-color: #FFFFFF !important;
            margin: 0;
            border: none;
            box-shadow: none;

            .card-gamificacao {
                display: none;
            }

            .grafico-gamificacao {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: flex-end;
                min-width: 500px;
                max-width: 500px;
                margin: 0 auto;
                position: relative;
                top: 0px;
                right: 0px;

                .grafico {
                    .pontuacao-total-gamificacao {
                        top: 136px;
                        right: 140px;
                        font-size: 16px;

                        span {
                            font-size: 40px !important;
                            font-weight: bold;
                        }
                    }
                }
            }

            .container-icone {
                position: static;
                margin-top: 80px !important;
                margin-bottom: 160px !important;

                .icone-desafio,
                .icone-ranking {
                    span {
                        font-size: 150px !important;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }

                    h2 {
                        font-size: 24px;
                    }

                }
            }

            .modulos-gamificacao {
                padding: 80px 30px !important;
                grid-template-rows: repeat(3, auto);
                gap: 80px 0px !important;

                .gamificacao-modulo:nth-child(1) {
                    grid-column-start: 1;
                    grid-column-end: -1;
                    grid-row: 1;
                }

                .gamificacao-modulo:nth-child(2) {
                    grid-column-start: 1;
                    grid-column-end: -1;
                    grid-row: 2;
                }

                .gamificacao-modulo:nth-child(3) {
                    grid-column-start: 1;
                    grid-column-end: -1;
                    grid-row: 3;
                }

                .gamificacao-modulo {

                    h3 {
                        font-weight: bold !important;
                        text-align: center;
                        margin-bottom: 80px !important;
                    }

                    .gamificacao-lista {
                        ul {
                            list-style: none;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            gap: 10px 10px;
                            flex: 1;

                            li {
                                display: flex;
                                flex-direction: row;
                                padding: 10px 10px 10px 0;
                                min-width: 200px !important;
                                max-width: 200px !important;

                                .cor-modulo {
                                    min-width: 12px;
                                    max-width: 12px;
                                    height: 50px;
                                }

                                .pontuacao-label {
                                    display: flex;
                                    flex-direction: column;
                                    margin-left: 10px;
                                    font-size: 16px !important;

                                    .pontuacao {
                                        font-size: 24px;
                                        font-weight: bolder;
                                    }

                                    .label-score {
                                        display: inline-block;
                                        min-width: auto !important;
                                        max-width: auto !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .desafios-gamificacao,
            .ranking-gamificacao {
                padding: 0 30px;
            }

            .desafios-gamificacao {

                ul {

                    li {
                        display: flex;
                        position: relative;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .imagem-desafio {
                            min-width: 15%;
                            max-width: 15%;
                            margin-bottom: 80px;

                            img {
                                display: inline-block;
                                min-width: 100% !important;
                                max-width: 100% !important;
                                min-height: 100% !important;
                                max-height: 100% !important;
                            }
                        }

                        .conteudo-desafio {
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            padding-left: 0px;

                            h3 {
                                font-size: 1.5rem;
                                margin-bottom: 15px;
                                text-align: center;
                            }

                            p {
                                font-size: 1rem;
                                text-align: center;
                            }
                        }

                        .grafico-desafio,
                        .lottie-desafio {
                            margin-top: 80px;
                            align-self: center;
                        }

                        .grafico-desafio {
                            min-width: 20% !important;
                            max-width: 20% !important;
                        }

                        .lottie-desafio {
                            min-width: 25% !important;
                            max-width: 25% !important;
                        }

                    }
                }
            }
        }
    }

}

@media screen and (max-width: 790px) {

    .container-gamificacao {
        background-color: #FFFFFF !important;

        .gamificacao-content {
            position: static;
            min-width: 100%;
            max-width: 100%;
            background-color: #FFFFFF !important;
            margin: 0;
            border: none;
            box-shadow: none;

            .grafico-gamificacao {
                position: relative;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                min-width: 100% !important;
                max-width: 100% !important;

                img {
                    width: 150px;
                }

                .grafico {
                    position: relative;
                    margin-top: 80px;

                    .pontuacao-total-gamificacao {
                        position: absolute;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        top: 140px;
                        right: 115px;
                        min-width: 70.5px;
                        max-width: 70.5px;
                        font-size: 16px;
                    }
                }
            }

            .grafico-gamificacao {
                position: relative;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                min-width: 100% !important;
                max-width: 100% !important;

                img {
                    width: 150px;
                }

                .grafico {
                    position: relative;
                    margin-top: 80px;

                    .pontuacao-total-gamificacao {
                        position: absolute;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        top: 140px;
                        right: 115px;
                        min-width: 70.5px;
                        max-width: 70.5px;
                        font-size: 16px;
                    }
                }
            }

            .desafios-gamificacao {
                ul {

                    li {

                        .imagem-desafio {
                            min-width: 20% !important;
                            max-width: 20% !important;
                        }

                        .conteudo-desafio {
                            min-width: 100%;
                            max-width: 100%;

                            h3 {
                                font-size: 1.3em;
                                text-align: center;
                            }

                            p {
                                text-align: center;
                                font-size: 1em;
                            }
                        }

                        .grafico-desafio {
                            min-width: 25% !important;
                            max-width: 25% !important;
                        }

                        .lottie-desafio {
                            min-width: 40% !important;
                            max-width: 40% !important;
                        }
                    }
                }
            }

            .ranking-gamificacao {

                .navegacao-tipo-ranking {
                    width: 80%;
                    margin: 0 auto 90px auto;
                }

            }

        }
    }

}

@media screen and (max-width: 480px) {

    .container-gamificacao {

        .gamificacao-content {

            .desafios-gamificacao {

                ul {

                    li {
                        .imagem-desafio {
                            min-width: 40% !important;
                            max-width: 40% !important;
                        }

                        .grafico-desafio {
                            min-width: 45% !important;
                            max-width: 45% !important;
                        }

                        .lottie-desafio {
                            min-width: 70% !important;
                            max-width: 70% !important;
                        }
                    }
                }
            }

            .ranking-gamificacao {

                .navegacao-tipo-ranking {
                    width: 95% !important;
                }

            }

        }
    }

}