.calendario-paper{
    padding: 5rem;
    width: 100%;
    /*display: flex;*/
    align-items: center;
    justify-content: center;
    min-height: 40rem;

    .titulo{
        padding-bottom: 2rem;
        display: flex;
        justify-content: center;
        text-align: center;
    }
    .iconeCalendario{
        min-width: 3rem !important;
        min-height: 3rem !important;
        margin-bottom: 2rem;
    }
    .iconeInfo{
        min-width: 3rem !important;
        min-height: 3rem !important;
    }
    .iconeZoom{
        min-width: 3rem !important;
        min-height: 3rem !important;
        cursor: pointer;        
    }
    .icones2{
        display: flex;
        justify-content: right;
        margin-right: 1rem;
    }
    

    @media screen and (max-width: 960px) { 
        padding: 2rem;
        .icones, .icones2{
            display: none;
        }
        .titulo{
            font-size: 2rem;
        }
    }

    // .rt-project-keys > .rt-project-key > .rt-project-key__entry{
    //     padding-left: 0px !important;
    // }
    .rt-project-key__entry div{
        display: none;
    }
}