.boxListItem {
    display: flex;
    flex-direction: row;
    gap: 25px;
    width: 100%;
    align-items: center;
    padding: 0.6rem;
    margin: 0px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.boxListItem:hover {
    box-shadow: 0px 0px 10px#ddd;
}