
.nav-link {
    min-width: 70vw !important;
    display: flex;
    flex-direction: row;
    flex-grow: 2;
    align-items: center;
    justify-content: space-between;        
    flex-wrap: nowrap;
    // overflow-x: hidden;
    overflow-x: scroll;

    // Ocultar scroll horizontal
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

}

.nav-link .nav-link-item {
    padding: 12px 24px;        
    white-space: nowrap; 
    min-width: auto;

    transition: ease-in-out;
}

.nav-link-item.active {
    color: #333 !important;
    // TODO: add active class
    // background-color: rgba(214, 214, 214, 0.289) !important;
    // background-color: rgb(255, 200, 200) !important;
    // border-radius: 30px !important; 
}

.nav-link-item.active::before {
    content: " ";
    width: 64px;
    height: 6px;
    border-radius: 12px;
    background-color: rgb(226, 226, 226);
    margin-right: 12px;
    display: block;
    position: absolute;
    align-items: center;
    bottom: 0px;
    left: calc(50% - 32px);
}

.informative {
    height: 50vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
