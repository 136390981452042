/* Estilização da scrollbar no Firefox */
.containerOportunidades {
    scrollbar-width: thin !important;
    scrollbar-color: #A8A8A8 transparent !important;
}
    
/* Estilização da scrollbar nos navegadores Chrome, Edge e Safari */
.containerOportunidades::-webkit-scrollbar {
    width: 8px !important;
}

.containerOportunidades::-webkit-scrollbar-track {
    background-color: transparent !important;
    margin: 10px !important;
}

.containerOportunidades::-webkit-scrollbar-thumb {
    background-color: #A8A8A8; 
    border-radius: 20px;
}