.popup {
    background: #fff !important;
    width: 20vw !important;
    min-height: 35px !important;
    border: 1px solid #5c5c5c7d !important;
    border-radius: 4px !important;
    position: absolute !important;
    top: 4.4rem !important;
    right: 1.8rem !important;
    padding: 0 0.4rem !important;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

    & > :first-child {
        border-bottom: 2px solid #ffc000 !important;
        margin-bottom: 0.4rem !important;

        & > :first-child {
            color: #2c2c2c !important; 
            font-weight: bold !important;
            font-size: 1rem !important;
        }

        & > :last-child {
            color: #2c2c2c7d !important;
        }
    }

    .nullValue {
        text-align: center !important;
        font-weight: bold !important;
        color: #a7a7a7 !important;
        padding: 8px 0 !important;
    }

    .area_notify {
        max-height: 10rem !important;
        overflow-y: auto !important;

        &::-webkit-scrollbar {
            width: 4px;
        }
          
        &::-webkit-scrollbar-track {
            background: #7070707d;
        }
          
        &::-webkit-scrollbar-thumb {
            background-color: #2c2c2c7a;
            border-radius: 20px;
            border: none;
        }
    }

    .area_btn_all {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        padding: 8px !important;

        &::before {
            content: "" !important;
            width: 10% !important;
            height: 1px !important;
            background-color: #ffc000 !important;
            margin-bottom: 4px !important;
        }

        button {
            color: #878787;
            border: 1px solid #878787;
            cursor: pointer;
        }
    }

    .item_list_request {
        display: flex !important;
        align-items: center !important;
        color: #2c2c2c !important;
        margin-bottom: 0.2rem !important;
        cursor: pointer !important;
        transition: background-color 0.2s !important;

        & > :first-child {
            margin-right: 0.2rem !important;
        }

        .text_list_request {
            max-width: 100% !important;
            white-space: nowrap !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;

            font-family: 'Roboto' !important;
            font-size: 0.9rem !important;
            font-weight: 500 !important;
        }

        &:hover {
            background-color: #efefef !important;
        }
    }
}