.seccao-trilhas-aprendizagem {
    margin: 40px 20px 20px 20px;
    padding: 30px 20px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 5px;

    .box-header {
        display: flex;
        width: 100% !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;

        &::after {
            content: "";
            width: 70px;
            height: 5px;
            border-radius: 5px;
            background-color: #BD0E0E;
        }

        h2 {
            font-size: 32px;
            padding: 0;
            text-align: center;
        }

    }

    .box-text-content {    
        margin-bottom: 28px;

        p {
            text-align: justify;
        }
    }

    .p-carousel {
        margin-left: auto;
        margin-right: auto;
        max-width: 80%;
        margin-bottom: 20px;    
        
        .p-carousel-item {
            max-width: 33.33%; 
        }
    }

}

@media screen and (max-width: 1024px) {
    .seccao-trilhas-aprendizagem {
        margin: 40px auto 0 auto;
        width: 90%;

        .p-carousel {
            max-width: 100%;
            margin-left: 0;
            margin-right: 0;

            .p-carousel-item {
                max-width: 100%;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .seccao-trilhas-aprendizagem {
        width: 100%;
        padding: 30px 0;
        margin: 40px 0 0 0 !important;
        
        .box-header {
    
            h2 {
                padding: 0 20px;
            }
    
        }    

        .box-text-content {    
            p {
                padding: 0 20px;
            }
        }
        
    }
}