.MuiTypography-subtitle1 {
    padding: 1rem;
}

.modalLink {
    display: flex !important;
}

.MuiAvatar-colorDefault {
    background-color: #70707063 !important;
}

.list-reset-subtitle,
.list-reset-title {
    padding: 0.8rem !important;
}

.list-reset-title {
    font-weight: bolder;
    text-align: right;
}

.list-reset-subtitle {
    font-style: italic;
    border-left: 1px solid rgba(187, 187, 187, 1);
}

.MuiFormControl-root {
    padding-bottom: 1rem !important;
}

// height: 100vh;

.body {
    background-color: white;
    // min-width: 40vw;
    min-height: 430px;
    // border-radius: 12px;
    overflow: hidden;

    .head {
        width: 100%;
        padding: 12px 24px;
        background-color: rgb(190, 15, 38);
        color: white;

        display: flex;
        align-items: center;
        justify-content: flex-start;

    }

    .content {
        padding: 12px 24px;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;      
    }
}

@media screen and(max-width: 768px) {
    .list-reset-title {
        text-align: left;
        line-height: 0px;
        margin: 0px !important;
    }
}