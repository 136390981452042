.result {
    background-color: #fafafa;
    border-radius: 10px;
}

.result:hover {
    background-color: #f5f5f5;
    cursor: pointer;
}

.result {
    display: grid;
    
    grid-gap: 12px;
    grid-template-rows:  minmax(100px, auto);
    grid-template-columns: repeat(12, 1fr);

    // grid-template-areas: "icon content content content content content";
}

.project-icon {
    grid-area: icon;
    grid-row-start: 1;    
    grid-column-start: 1;
    grid-column-end: 3;
}

.project-icon {
    min-height: 132px;
    // height: 72px;
    background-color: antiquewhite;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        padding: 12px;
    }
}

.project-content {  
    grid-area: content;      
    grid-row-start: 1;    
    grid-column-start: 3;
    grid-column-end: -1;
    display: flex;
    flex-direction: column;     
}



@media screen and (max-width: 768px) {
    .result {
        grid-template-rows:  minmax(100px, auto);
        grid-template-columns: repeat(6, 1fr);
        // grid-template-areas: "icon content content content content content";
    }    

    .project-icon {
        img {
            padding: 4px;
        }
    }
}