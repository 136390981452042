.button_info {
    position: relative !important;

    background: #FFF !important;
    border-radius: 36px !important;
    font-weight: bold !important;
    font-size: 1.2rem !important;
    display: flex !important;
    justify-content: space-around !important;
    align-items: center !important;
    min-width: 8rem !important;
    margin-left: 0.5rem !important;
}

@media screen and (max-width: 840px) {
    .button_info {
        border: 1px solid #bd0e0e !important;
        font-size: 1rem !important;
    }
}