.lista{
    max-width: 90%;
}

.evento, .fc-view-harness{
    font-family: "Lato";
}

.evento{
    span{
        white-space: normal;
    }
    cursor: pointer;
}

.pendente{
    background-color: darkorange !important;
    color: white !important;
    border: unset !important;
}

.cancelado{
    background-color: antiquewhite !important;
    border: unset !important;
    .evento{
        color: #495057 !important;
    }
    
}

.concluido{
    background-color: honeydew  !important;
    border: unset !important;
    .evento{
        color: #495057 !important;
    }
}

.MuiAvatar-circular.concluido{
    color:#488148;
    border: 1px solid #a1ffa1 !important;
}

.MuiAvatar-circular.cancelado{
    color:rgb(209, 180, 142);
}


.footerBar{
    margin-top: 1rem;
}

.fc-button{
    color: white !important;
    background-color: #bd0e0e !important;
    border: 1px solid #bd0e0e !important;
    background: #bd0e0e !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
    
}
.fc-button:focus{
    box-shadow: 0 0 0 0.2rem #ffc000 !important;
}

@media screen and(max-width: 768px) {
    .lista{
        max-width: 100%;
    }
    .fc-button{
        font-size: 0.7rem !important;
    }
}
@media screen and(min-width: 769px) {
    
    .footerBar{
        div{
            .MuiGrid-grid-md-9{
                justify-content: left !important;
            }
        }
    }
}