.seccao-busca-vagas {
    margin: 40px 20px 20px 20px;
    position: relative;
    padding: 30px 20px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    display: flex;
    justify-content: center;

    .box-header {
        display: flex;
        width: 100% !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;

        &::after {
            content: "";
            width: 70px;
            height: 5px;
            border-radius: 5px;
            background-color: #BD0E0E;
        }

        h2 {
            font-size: 32px;
            padding: 0;
            text-align: center;
        }
    }

    .box-content {
        position: absolute;
        top: 114px;
        width: 60%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .input01 {
            border-radius: 100% !important;
            width: 70%;
            background-color: #ffffff;
            padding: 0 !important;
            margin-right: 10px;
        }

        .input02 {
            width: 30%;
            background-color: #ffffff;
            padding: 0 !important;
        }
    }
}

.seccao-principais-vagas {
    margin: 40px 20px 20px 20px;
    padding: 30px 20px;
    height: auto;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 5px;

    .box-header {
        display: flex;
        width: 100% !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;

        &::after {
            content: "";
            width: 70px;
            height: 5px;
            border-radius: 5px;
            background-color: #BD0E0E;
        }


        h2 {
            font-size: 32px;
            padding: 0;
            text-align: center;
        } 

    }

    .box-content {
        .p-carousel {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
            max-width: 80%;  
            
            .p-carousel-item {
                max-width: 33.33%;
            }
        }

        .lista-vagas {
            padding: 0;

            .imagem-lateral-item-lista-vagas {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0 10px;
                width: 120px;
                height: 100px;
                border-radius: 5px;

                span {
                    display: inline-block;
                    width: 100%;
                    color: #FFFFFF;
                    margin-top: 10px;
                    text-align: center;
                    font-size: 13px;
                }
            }

            .item-lista-vagas {
                padding: 0;
                margin: 20px 0;

                .texto-item-lista {
                    padding-left: 10px;
                    width: 100% !important;

                    h2 {
                        font-weight: 700;
                    }
                }               
            }
        }
    }

}

@media screen and (max-width: 1024px) {
    .seccao-busca-vagas {
        margin: 40px auto 0 auto;
        width: 90%;
    
        .box-content {
            width: 90%;
    
            .input01 {
                width: 60%;
            }
    
            .input02 {
                width: 40%;
            }
        }
    }

    .seccao-principais-vagas {
        margin: 40px auto 0 auto;
        width: 90%;

        .box-content {
            .p-carousel {
                max-width: 100%;
                margin-left: 0;
                margin-right: 0;
    
                .p-carousel-item {
                    max-width: 100%;
                }
            }
        }
        
    }
}

@media screen and (max-width: 480px) {
    .seccao-busca-vagas {
        width: 100%;
    }

    .seccao-principais-vagas {
        width: 100%;
        margin: 40px 0 0 0 !important;
        padding: 30px 0;

        .box-header {    
    
            h2 {
                font-size: 32px;
                padding: 0 20px;
                text-align: center;
            } 
    
        }
    }
}