.welcome_subject {
    position: relative;
    z-index:4;
}

// Quando a tela é mobile e possui dimensões de até 375px exibe o <Welcome /> com margem superior de 15vh
// Funciona bem para dispositivos pequenos como o iPhone SE
@media screen and (max-width: 375px) {
    .welcome_subject {
        margin-top: 5vh !important;
    }
}

// Quando a tela possui uma resolução HD exibe o <Welcome /> abaixo dos cards, mas ainda visível na tela
@media screen and (max-width: 1080px) and (max-height: 720px ) {
    .welcome_subject {
        margin-top: 30vh !important;
    }
}

// Quando é acessado no dispositivo móvel e a tela possui orientação landscap "empurra" o <Welcome /> para baixo
@media  screen and (max-height: 640px) and (orientation: landscape) {
    .welcome_subject {
        margin-top: 100vh !important;
    }
}

.modal { 

    display: flex;
    justify-content: center;
    align-items: center;    
    // width: 880px;
    // height: 100vh;

    overflow: hidden;
    .body { 
        background-color: white;
        // min-width: 800px;
        // min-height: 480px;
        // border-radius: 12px;
        overflow: scroll;

        .head { 
            width: 100%;
            padding: 12px 24px;            
            //background-color: rgb(190, 15, 38);
            color: white;

            display: flex;
            align-items: center;
            justify-content: flex-start;
            
        }
        .content { 
            padding: 12px 24px;      
            // display: flex;
            // flex-direction: column;
            // justify-content: space-between;      
        }
    }
}

.avatar { 
    width: 96px;
    height: 96px;
    // border-radius: 12px;
    object-fit: cover;

    // border: solid 2px rgb(85, 85, 85);
}

.infoPerfil {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;

    .img_perfil_questions {
        width: 5rem !important;
        height: 5rem !important;

        h6 {
            font-size: 2rem !important;
        }
    }

    .name_studenty {
        font-size: 1rem !important;
        font-weight: bold !important;
    }

    .ra_studenty {
        font-size: 0.8rem !important;
        font-weight: bold !important;
        color: #6f6f6f !important;
    }
}
