.await_request {
    margin-bottom: 8px !important;
    font-size: 1rem !important;
    font-weight: bold !important;
}

.area_data_confirmation {
    width: 100% !important;
    border: 1px solid #2c2c2c7d !important;
    border-radius: 0.4rem !important;
    padding: 0.6rem !important;
    word-wrap: break-word !important;

    .info_data {
        font-size: 1.4rem !important;
        font-weight: 500 !important;
        text-align: left !important;
        margin-bottom: 0.4rem !important;

        > .tag_situation {
            background-color: #989898 !important;
            color: #fff !important;
            padding: 4px 6px !important;
            font-size: 0.8rem !important;
            border-radius: 4px !important;
            text-align: center !important;
            text-transform: uppercase !important;
            font-weight: bold !important;

            &.deferred {
                background-color: #13930ae0 !important;
            }

            &.rejected {
                background-color: #bd0e0e !important;
            }
        }
    }

    & > a {
        color: #0e68bd !important;
        font-weight: bold !important;
        text-transform: initial !important;
        font-size: 1rem !important;
        border: 1px solid #0e68bd !important;
        margin-bottom: 0.4rem !important;
    }

    .area_link {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        gap: 8px !important;
        margin: 1rem !important;

        > span {
            text-transform: uppercase !important;
            font-family: "Lato" !important;
            font-weight: bold !important;
            font-size: 0.8rem !important;
            color: #4c4c4c !important;
        }
    }

    .footer_undo {
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;

        width: 100% !important;
        background-color: #2c2c2c !important;
        padding: 0.2rem 0.6rem !important;
        border-radius: 4px !important;

        & > h6 {
            color: #fff !important;
            font-size: 1rem !important;
        }

        & > button {
            color: #ffc000 !important;
        }
    }
}

.textDelete {
    text-align: center !important;
    font-size: 1rem !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
    color: #bd0e0e !important;
}

@media screen and (max-width: 840px) {
    .area_data_confirmation {
        .info_data {
            font-size: 1rem !important;
            margin-bottom: 0.2rem !important;
        }

        .area_link {
            a, button {
                font-size: 0.6rem !important;
            }
        }
    
        .footer_undo {
            & > h6 {
                font-size: 0.7rem !important;
            }
    
            & > button {
                font-size: 0.6rem !important;
            }
        }
    }
}