 /* Estilização da scrollbar nos navegadores Chrome, Edge e Safari */
.MuiDialog-paperScrollPaper::-webkit-scrollbar {
    width: 8px !important;
}

.MuiDialog-paperScrollPaper::-webkit-scrollbar-track {
    background-color: transparent !important;
    margin: 10px !important;
}

.MuiDialog-paperScrollPaper::-webkit-scrollbar-thumb {
    background-color: #A8A8A8; 
    border-radius: 20px;
}