.MuiDrawer-paperAnchorRight {
    top: 64px !important;
    height: calc(100vh - 64px) !important;
}

// .Drawer-MuiBackdrop-root {
//     .MuiBackdrop-root {
//         top: 63px !important;
//         display: none;
//     }
// }

.listDialogPopupNotificationsMessage {
    width: 450px;
    flex-shrink: 0;
}

.ListTutorMensagens {
    height: calc(100vh - 220px) !important;
    overflow-y: scroll;
    scrollbar-width: thin !important;
    scrollbar-color: #707070 !important;

    &::-webkit-scrollbar {
        width: 0.8rem !important;
    }

    &::-webkit-scrollbar-track {
        background: transparent !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #707070 !important;
        border-radius: 0.4rem !important;
        border: 3px solid #f0f0f0 !important;
    }
}

@media screen and (max-width: 450px) {

    .listDialogPopupNotificationsMessage {
        width: 100vw;
        flex-shrink: 0;
    }

    .MuiDrawer-paperAnchorRight {
        top: 56px !important;
        height: calc(100vh - 56px) !important;
    }

}