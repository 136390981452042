.footer{

    font-family: 'Roboto', Helvetica, Arial, sans-serif !important;

    min-height: 65vh;

    padding-top: 10vh;

    margin-top: 2rem !important;
    margin-bottom: 0 !important;
    // padding: 2rem;
    width: 100%;
    // background-color:#fcfcfc !important;
    // background-color:#fff !important;
    background-color:#333 !important;
    color: white;

    hr{
        margin-top: 2rem;
        margin-bottom: 2rem;
        background-color: #ececec;
    }

    svg{
        // fill: #333;
        // fill: white;
        // font-size: 1.3rem;
        // max-width: 2rem;
        // max-height: 2rem;
        background-color: transparent;
    }

    .links,
    .links > * {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center !important;
        width: 100%;
    }
    .link-icons > * {
        margin: 4px;
        padding: 6px;        
    }

    .buttonFooter{
        margin-left: 2rem;
    }
    // .MuiFormHelperText-root, .MuiInputBase-input{
    //     color: white !important;
    // }
    .MuiInput-underline:before{
        border-bottom: 1px solid white;
    }

    .MuiFormLabel-root {
        color: white !important;
    }
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: white !important;
    }

    .MuiFormLabel-root.Mui-focused {
        color: white !important;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: white !important;
    }

    p {
        font-family: inherit !important;
    }

    .text-helper-email{
        font-size: 0.70rem;
        font-family: inherit !important;

        a{
            text-decoration: underline !important;
        }
    }
}

.footer {
    .list-item-link {
        margin-top: 12px;
        margin-bottom: 12px;
        font-family: inherit !important;
    }

    .list-item-title-link {
        font-weight: bold;
        font-family: inherit !important;
        // border-bottom: solid 1px grey;
    }

    .links {
        svg{
            fill: rgb(255, 255, 255);
            // fill: white;
            font-size: 3rem;
            max-width: 2rem;
            max-height: 2rem;
        }
    }
}

@media screen and (min-width: 481px) {
    .link-icons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
}

@media screen and (max-width: 480px) {

    .boxEventosHoje {
        flex-direction: column !important;

        p {
            text-align: center;
        }

        .buttonFooter {
            margin-left: 0;
        }
    }

    .textoDireitosReservados {
        text-align: center;
    }

    .boxPrivacidade {
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;

        p {
            margin-bottom: 10px;
        }
    }

    .link-icons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
        
}
