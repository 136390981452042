.titulo-react-joyride {
    margin-bottom: 20px;
}

.esconder-botao-proxima-etapa,
.esconder-botao-close {
    display: none;
}

.exibir-botao-proxima-etapa,
.exibir-botao-close {
    display: block;
}