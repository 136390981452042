.area_title_details {
    background-color: #bd0e0e !important;

    .title_details_list_solicitations {
        text-align: center !important;
        color: #fff !important;
        font-weight: bold !important;
        font-size: 1.6rem !important;
    }
}

.to_meet {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

    background-color: #f0f0f0 !important;
}
.title_to_meet {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;

    margin: 0.6rem !important;
    text-align: center !important;

    font-size: 1.2rem !important;
    color: #2c2c2c !important;

    &::after {
        content: "" !important;
        width: 0.8rem !important;
        height: 2px !important;
        background-color: #bd0e0e !important;
    }
}