.appbar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem !important;

    .navbar {
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
        width: 100vw !important;

        .area_buttons {
            .navButton {
                margin-right: 10px !important;
                text-transform: none !important;
                color: #fff !important;
                font-weight: 400 !important;
                position: relative !important;

                &.active {
                    border-radius: 0 !important;
                    border-bottom: 2px solid #fff !important;
                }

                &:hover {
                    .menu-dropdown {
                        display: block !important;
                    }
                }

                .menu-dropdown {
                    display: none !important;

                    border: 1px solid #8f8f8f7d !important;
                    border-radius: 4px !important;
                    padding: 0.6rem !important;
                    position: absolute !important;
                    top: 100% !important;
                    // bottom: -100% !important;
                    background-color: #fafafa !important;
                    min-width: 14rem !important;

                    .title_dropdown {
                        color: #2c2c2c !important;
                        text-align: left !important;
                        font-weight: 500 !important;
                        font-family: "Roboto" !important;
                        border-bottom: 2px solid #ffc000 !important;
                    }

                    .item_menu {
                        display: flex !important;
                        justify-content: flex-start !important;
                        align-items: center !important;
                        padding: 0.2rem !important;

                        >svg {
                            color: #2c2c2c !important;
                        }

                        >h6 {
                            color: #2c2c2c !important;
                            margin-left: 0.2rem !important;
                            font-family: "Roboto" !important;
                            font-weight: 400 !important;
                        }
                    }
                }
            }

            .iconNotification {
                font-size: 1.8rem !important;
                color: #fff !important;
            }
        }
    }
}

.button_portfolio_avatar {
    margin-left: 0.8rem !important;
    background: #fff !important;
    border-radius: 36px !important;
    height: 2rem !important;


    .imgPerfil {
        border-radius: 36px !important;
        width: 2.8rem !important;
        height: 2.8rem !important;
        margin-left: -1rem !important;
        margin-right: 0.4rem !important;

        background-color: #c7c7c7 !important;
    }

    .title_button_portifolio {
        font-size: 0.8rem !important;
        font-weight: 700 !important;
        text-align: right !important;
        text-transform: none !important;
    }
}

.iconMenu {
    color: #fff !important;
    font-size: 2rem !important;
    border: 1px solid #fff !important;
    border-radius: 2px !important;
}


.logo {
    // height: 72px;
    padding: 6px;
    width: 8rem !important;
}

.basic-material-appbar {
    border-bottom: 1px solid rgba(164, 164, 164, 0.2);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

@media screen and (max-width: 1400px) {
    .button_portfolio_avatar {
        .title_button_portifolio {
            font-size: 0.7rem !important;
            font-weight: 700 !important;
            text-align: left !important;
            text-transform: none !important;
        }
    }
}