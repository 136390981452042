.perfil_studenty {
    .buttonClose {
        border: none !important;
        padding: 0 !important;
        margin:  0 0 0.4rem 0 !important;

        &:first-child { 
            color: #f0f0f0 !important;
        }
    }

    .name_studenty_menu {
        text-align: right !important;
        font-family: 'Roboto' !important;
        font-weight: 500 !important;
        color: #fff !important;
    }
    
    .ra_studenty_menu {
        text-align: right !important;
        font-family: 'Roboto' !important;
        font-weight: 500 !important;
        font-size: 0.8rem !important;
        color: #fff !important;
    }
}

.info_perfil_secondary {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
    flex-direction: column !important;
    width: 100% !important;

    button {
        margin-top: 10px !important;
        border: 1px solid #fff !important;
        border-radius: 36px !important;
        color: #fff !important;
        font-size: 0.6rem !important;
    }
}

.icon_menu_item {
    color: #2c2c2c !important;
}

@media screen and (max-width: 840px) {
    .perfil_studenty {
        .name_studenty_menu {
            font-size: 0.8rem !important;
        }
    }
}