.banner {
    grid-column-start: 1;
    grid-column-end: 3;
}

.box-content {
    margin-left: 15px;
    grid-column-start: 3; 
    grid-column-end: -1;

    .box-busca {
        min-width: 100% !important;
    }

    .box-botoes {
        display: flex;
        padding: 0;
        flex-direction: row;
        justify-content: flex-start;
        min-width: 100% !important;
        margin-bottom: 16px !important;

        & * {
            color: white;
            text-transform: capitalize;
        }
    }
}

@media screen and (max-width: 960px) {
    .box-content {
        margin-left: 0px;
        grid-column-start: 1;
        grid-column-end: -1;
    
        .box-botoes {               
            button {
                font-size: 10px !important;
            }
        }
    }
}
