.container-dialog {
    min-height: 100% !important;
    max-height: 100% !important;

    .card-etapa-tutorial-mobile {
        min-width: 100% !important;
        max-width: 100% !important;
        min-height: 100vh !important;
        max-height: 100vh !important;
        border-radius: 0px !important;
        box-shadow: none !important;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(3, auto);

        .box-image {
            grid-column-start: 1;
            grid-column-end: -1;
            grid-row: 1;
            padding: 40px !important;
        }

        .box-content {
            grid-column-start: 1;
            grid-column-end: -1;
            grid-row: 2;
            padding: 0 20px !important;

            h2 {
                max-width: 100% !important;
                font-size: 21px;
                text-align: center !important;
                font-weight: 500;
            }

            p, a {
                text-align: center !important;
                margin-top: 15px !important;
            }
        }

        .box-botoes {
            grid-row: -1;
            grid-column-start: 1;
            grid-column-end: -1;
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 0px !important;

            .botao-pular,
            .botao-avancar {
                color: #ffffff !important;
                text-transform: capitalize !important;
                font-size: 14px;
            }
        }
    }
}