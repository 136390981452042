.head {    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px; 
    color: #FFFFFF; 

    p {
        margin-left: 10px;
        font-weight: bold;
    }
}

.dialogBlocoNotas {

    .editorBlocoNotas {
        padding: 24px;
        min-width: 1200px;
    }
    
    .box-botoes {
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        .botao-salvar {
            display: flex;
            padding: 10px 24px 10px 0px;
            flex-direction: row;
            justify-content: flex-end;
            border-top: 1px solid rgba(0, 0, 0, 0.1);

            button {
                min-width: 80px !important;
            }
        }

    }
}

@media screen and (max-width: 1270px) {
    .dialogBlocoNotas {
        .editorBlocoNotas {
            min-width: auto;
        }
    }
}

@media screen and (max-width: 420px) {
    .dialogBlocoNotas {
        .editorBlocoNotas {
            padding: 24px 10px;
        }
    }
}