.containerEventosCursos {
    // height: 100%;
    height: auto;
    width: 50% !important;
    // border: 2px solid rgba(0, 0, 0, 0.2);    
    border-radius: 4px;
    // box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.4);
    padding: 20px !important; 
    // margin-left: 10px !important;
    margin: 0 12px;

    border: 1px solid rgba(164, 164, 164, 0.2);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    
    background-color: white;
    //&:hover {
    //    cursor: pointer;
    //    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    //}
}  

@media (max-width: 1024px) {
    .containerEventosCursos {
        height: 50%;
        width: 100% !important;
        border: 2px solid rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.4);
        padding: 20px !important; 
        margin-left: 0px !important;
        margin-top: 10px !important;
    }  
}

.typographyEventoCursos {
    font-weight: bold !important;
    color: black !important;
}

.listCursos {
    height: 90%;
    width: 100%;
    overflow-y: scroll;
    scrollbar-width: thin !important;
      scrollbar-color: #707070 !important;
  
      &::-webkit-scrollbar {
          width: 0.8rem !important;
      }
  
      &::-webkit-scrollbar-track {
          background: transparent !important;
      }
  
      &::-webkit-scrollbar-thumb {
          background-color: #707070 !important;
          border-radius: 0.4rem !important;
          border: 3px solid #f0f0f0 !important;
      }
}

@media (max-width: 1024px) {
    .listCursos {
        height: 80%;
        width: 100%;
        overflow-y: scroll;
        scrollbar-width: thin !important;
          scrollbar-color: #707070 !important;
      
          &::-webkit-scrollbar {
              width: 0.8rem !important;
          }
      
          &::-webkit-scrollbar-track {
              background: transparent !important;
          }
      
          &::-webkit-scrollbar-thumb {
              background-color: #707070 !important;
              border-radius: 0.4rem !important;
              border: 3px solid #f0f0f0 !important;
          }
    }
}

.listItemPrimaryCursos{
    color: #bd0e0e;
}

.typographyTituloCursos {
    font-weight: bold !important;
    color: black !important;
}

.divlink:active {
    color: red !important;
}

.dadosEventosCursos {
    display: flex !important;
    flex-direction: row !important;
    margin-top: 10px !important;
}

.secondaryCursos{
    justify-content: space-between;
}

.dadosIndividuaisCursos {
    margin-left: 10px !important;
    margin-right: 0px !important;
}

.linkCertificadoCursos{
    margin-top: 10px !important;
}

@media (max-width: 1024px) {
    .linkCertificadoCursos{
        margin-top: 0px !important;
    }
}

@media (max-width: 1250px) {
    .dadosIndividuaisCursos {
        margin-left: 120px !important;
    }
}

@media (max-width: 1170px) {
    .dadosIndividuaisCursos {
        margin-left: 100px !important;
    }
}

@media (max-width: 1125px) {
    .dadosIndividuaisCursos {
        margin-left: 90px !important;
    }
}

@media (max-width: 1000px) {
    .dadosIndividuaisCursos {
        margin-left: 80px !important;
    }
}

@media (max-width: 980px) {
    .dadosIndividuaisCursos {
        margin-left: 40px !important;
    }
}

@media (max-width: 800px) {
    .dadosEventosCursos {
        display: flex !important;
        margin-top: 10px !important;
        flex-direction: column !important;
    }

    .dadosIndividuaisCursos {
        margin-left: 0px !important;
    }
}