.middle {
    border: solid red;
    width: 540px;
    padding: 12px 24px;
}

.container-login {
    margin: 0;
    min-height: 100%;
    max-height: 100%;

    .secao {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        min-height: 95%;
        max-height: 95%;

        .seccao-banner-login {
            min-width: 46%;
            max-width: 46%;
            min-height: 100%;
            max-height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            // border: 1px solid black;

            .banner-login {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-content: center;
                justify-content: center;
                align-items: center;
                position: relative;

                #lottie {
                    position: relative;
                    background-color: transparent;
                    width: 55%;
                    height: auto;
                    display: block;
                    overflow: hidden;
                    transform: translate3d(0, 0, 0);
                    text-align: center;
                    opacity: 1;
                    top: 40px;
                    // border: 1px solid black;
                }
            }

            .textos-banner-login {
                text-justify: center;
                position: relative;
                bottom: 90px;
                // border: 1px solid black;

                h3 {
                    font-size: 2.2rem !important;
                    font-weight: 700 !important;
                    text-align: center !important;
                }

                p {
                    font-size: 1.2rem !important;
                    color: #494747 !important;
                    text-align: center !important;
                }

                .logos-banner-login {
                    min-width: 100%;
                    max-width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 40px;
                    margin-top: 40px;
                }
            }
        }

        .seccao-formulario-login {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-width: 34%;
            max-width: 34%;
            min-height: 100%;
            max-height: 100%;
            margin: 0;
            padding: 0;
            // border: 1px solid black;

            label {
                display: block;
                margin-bottom: 10px;
                color: #0000008A;
            }

            h2 {
                text-align: center;
                font-size: 24px;
                margin-bottom: 20px;
            }

            #btnLogin {
                margin-bottom: 10px;
            }

            .footer-formulario-login {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 24px;
                margin-bottom: 20px;

                a {
                    display: block;
                    margin-bottom: 10px;
                    text-decoration: none;
                }
            }

            .texto-header-formulario-login {
                display: none;
            }

            .MuiFormControl-root {
                padding-bottom: 10px !important;
            }

            .MuiFormControlLabel-root {
                margin: 0 16px 0 -11px;
            }

        }
    }

    .footer-login {
        min-height: 5%;
        max-height: 5%;
        text-align: center;
        color: grey;
        // border: 1px solid black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        a {
            text-decoration: none;
        }
    }
}

.formulario-login {
    padding: 24px;
}

@media screen and (max-width: 1280px) {
    .container-login {

        .secao {
            .seccao-banner-login {

                .banner-login {
                    #lottie {
                        width: 60%;
                    }
                }

                .textos-banner-login {
                    bottom: 80px;

                    h3 {
                        font-size: 1.8rem !important;
                        font-weight: 700 !important;
                        text-align: center !important;
                    }

                    p {
                        font-size: 1rem !important;
                        color: #494747 !important;
                        text-align: center !important;
                    }

                }

            }
        }

    }
}

@media screen and (max-width: 1280px) {
    .container-login {

        .seccao-banner-login {

            .banner-login {
                #lottie {
                    width: 70%;
                    height: 10%;
                }
            }

            .textos-banner-login {
    
                h3 {
                    font-size: 2rem !important;
                    font-weight: 700 !important;
                    text-align: center !important;
                }
    
                p {
                    font-size: 1rem !important;
                    color: #494747 !important;
                    text-align: center !important;
                }
    
            }

        }
    }
}

@media screen and (max-width: 960px) {

    .container-fullscreen {
        min-height: 100% !important;
        max-height: 100% !important;

        .container-login {
            .secao {
                min-height: 90% !important;
                max-height: 90% !important;
                flex-direction: column;
                justify-content: center;

                .seccao-banner-login {
                    display: none;
                }

                .seccao-formulario-login {
                    padding: 0px !important;
                    min-width: 70%;
                    max-width: 70%;
                    min-height: 100% !important;
                    max-height: 100% !important;

                    .formulario-login {
                        margin: 0;
                        padding: 0 24px;

                        .footer-formulario-login {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;
                            margin: 20px 0;

                            a {
                                display: block;
                                margin-bottom: 10px;
                                text-decoration: none;
                            }
                        }

                        .texto-header-formulario-login {
                            display: block;
                            margin-top: 18px !important;

                            h3 {
                                font-size: 1.2rem !important;
                                font-weight: 700 !important;
                                text-align: center !important;
                            }

                            p {
                                font-size: 0.8rem !important;
                                color: #494747 !important;
                                text-align: center !important;
                            }

                        }

                        h5 {
                            font-size: 1.2rem !important;
                        }

                        .MuiFormControl-root {
                            padding-bottom: 10px !important;
                        }

                        .MuiFormControlLabel-root {
                            margin: 0 16px 0 -11px;
                        }

                    }
                }
            }

            .footer-login {
                min-height: 10% !important;
                max-height: 10% !important;
                padding: 0 24px;
            }
        }
    }

}

@media screen and (max-width: 480px) {

    .container-fullscreen {
        .container-login {
            .secao {
                .seccao-formulario-login {
                    padding: 0px !important;
                    min-width: 100%;
                    max-width: 100%;

                    .formulario-login {
                        margin: 0;
                        padding: 0 24px;

                        .footer-formulario-login {
                            margin: 0 !important;
                        }
                    }
                }
            }
        }


    }

}