.popup_notifications {
    display: none !important;

    &.open {
        display: block !important;
        z-index: 9999 !important;

        background: #fff !important;
        min-width: 15vw !important;
        max-width: 20rem !important;
        min-height: 2.1rem !important;
        border-radius: 4px !important;
        position: absolute !important;
        top: 3rem !important;
        right: 1rem !important;
        padding: 0 0.4rem !important;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

        & > :first-child {
            border-bottom: 2px solid #ffc000 !important;
            margin-bottom: 0.4rem !important;
            padding: 0 !important;

            & > :first-child {
                color: #2c2c2c !important; 
                font-weight: 500 !important;
                font-size: 0.9rem !important;
                font-family: "Roboto" !important;
            }

            & > :last-child {
                color: #2c2c2c7d !important;
            }
        }

        .nullValue {
            text-align: center !important;
            font-weight: bold !important;
            font-size: 0.8rem !important;
            color: #a7a7a7 !important;
            padding: 8px 0 !important;
        }

        .area_notify {
            max-height: 10rem !important;
            overflow-y: auto !important;

            &::-webkit-scrollbar {
                width: 4px;
            }
            
            &::-webkit-scrollbar-track {
                background: #7070707d;
            }
            
            &::-webkit-scrollbar-thumb {
                background-color: #2c2c2c7a;
                border-radius: 20px;
                border: none;
            }
        }

        .area_btn_all {
            display: flex !important;
            flex-direction: column !important;
            justify-content: center !important;
            align-items: center !important;
            padding: 8px !important;

            &::before {
                content: "" !important;
                width: 10% !important;
                height: 1px !important;
                background-color: #ffc000 !important;
                margin-bottom: 4px !important;
            }

            button {
                color: #878787;
                border: 1px solid #878787;
                cursor: pointer;
            }
        }


        .item_content {
            width: 100% !important;
            padding: 0.2rem !important;

            display: flex !important;
            flex-direction: column !important;
            align-items: flex-start !important;

            .date_notification {
                width: 100% !important;
                text-align: right !important;
                color: #777 !important;
                font-weight: bold !important;
                font-size: 0.6rem !important;
            }

            .item_list_request {
                display: flex !important;
                align-items: center !important;
                color: #2c2c2c !important;
                max-width: 100% !important;
    
                >svg {
                    color: #dadada !important;
                    font-size: 0.8rem !important;
                    margin-right: 0.2rem !important;
                }
    
                .text_list_request_notification {
                    font-family: 'Roboto' !important;
                    font-size: 0.8rem !important;
                    font-weight: 500 !important;
                }
    
                .text_list_request_notification.no_fullWidth_text {
                    white-space: nowrap !important;
                    overflow: hidden !important;
                    text-overflow: ellipsis !important;
                }

                .text_list_request_notification.readed {
                    font-weight: 400 !important;
                }
            }
        }

        .item_content.readed {
            background-color: #f1efef !important;
        }
    }
}