.card-depoimento {
    min-width: 100% !important;
    max-width: 100% !important;
    box-sizing: border-box;
    height: 200px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    .box-header-depoimento {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 12px 12px 0 12px !important;
        font-family: 'Lato';
        height: 70px !important;

        .box-sub-header {
            margin-left: 16px;

            h3 {
                font-weight: 500;
                font-size: 17px;
                text-transform: capitalize !important;
            }

            #subtitulo01 {
                font-weight: normal;
                color: #0000008A;
                font-size: 15px;
            }

            #subtitulo02 {
                font-weight: normal;
                font-size: 13px;
                color: rgba(0, 0, 0, 0.8);
            }
        }
    }

    .box-content-depoimento {
        height: 130px !important;
        overflow-y: auto;

        /* Estilização da scrollbar no Firefox */
        scrollbar-width: thin !important;
        scrollbar-color: #A8A8A8 transparent !important;

        p {
            color: #0000008A;
            padding: 12px !important;
            font-size: 0.83rem;
            text-align: justify;
            word-wrap: break-word;
            min-height: auto;
            max-height: auto;
        }
    }

    /* Estilização da scrollbar nos navegadores Chrome, Edge e Safari */
    .box-content-depoimento::-webkit-scrollbar {
        width: 4px !important;
    }

    .box-content-depoimento::-webkit-scrollbar-track {
        background-color: transparent !important;
        margin: 10px !important;
    }

    .box-content-depoimento::-webkit-scrollbar-thumb {
        background-color: #A8A8A8;
        border-radius: 20px;
    }

}

@media screen and (max-width: 1024px) {
    .card-depoimento {
        margin: 0;
    }
}

@media screen and (max-width: 480px) {
    .card-depoimento {

        .box-header-depoimento {
            line-height: .9em;

            .box-sub-header {
                h3 {
                    font-size: 14px !important;
                }

                #subtitulo01 {
                    font-size: 12px !important;
                }

                #subtitulo02 {
                    font-size: 11px !important;
                }
            }
        }

        .box-content-depoimento {
            p {
                font-size: 80% !important;
            }
        }
    }
}