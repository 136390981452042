.item-lista-contribuicoes {
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    .item-lista-conteudo {
    
        grid-column-start: 1;
        grid-column-end: -1;

        h2 {
            font-size: 24px !important;
            color: #000000;
            font-weight: bolder;
            font-size: 17px;
            text-align: center;
            padding: 20px 0 10px 0;

            strong {

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;

                &::after {
                    content: "" !important;
                    align-self: center;
                    width: 25px !important;
                    height: 1.5px !important;
                    border-radius: 5px !important;
                    background-color: #BD0E0E !important;
                }
            }
            
        }

        p {
            padding: 0;
            margin: 0;
        }

        .item-lista-texto {
            font-size: 18px !important;
            border-radius: 5px;
            color: #000000e5;
            text-align: justify;
            padding: 10px 0 10px 10px !important;
            background-color: #EDEDED87;
        }

        .item-lista-subconteudo {
            color: #000000e5;
            font-size: 16px;
            text-align: justify;
            padding-top: 10px;

            .texto {
                color: #2C2C2CA7!important;
            }
        }

        .item-lista-box-botao {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 20px 0;
        }

    }
}

@media screen and (max-width: 960px) {
    .item-lista-contribuicoes {

        .item-lista-conteudo {
            grid-column-start: 1;
            grid-column-end: -1;
            grid-row: -1;
    
            h2 {
                font-size: 16px;
                padding: 20px 0 10px 0;
            }
    
            p {
                padding: 0;
                margin: 0;
            }
    
            .item-lista-texto {
                text-align: justify;
                padding: 10px 0 20px 0;
            }
    
            .item-lista-subconteudo {
                padding-top: 10px;
                text-align: left;
            }
    
        }
    }
}