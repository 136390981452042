
.navbar_header {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.iconButtonAppBar {
    border-radius: 36px !important;
    padding: 6px !important;
    font-size: 2rem !important;
}

.infoPerfil {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;

    .img_perfil {
        width: 5rem !important;
        height: 5rem !important;
    }

    .name_studenty {
        font-size: 1rem !important;
        font-weight: bold !important;
    }

    .ra_studenty {
        font-size: 0.8rem !important;
        font-weight: bold !important;
        color: #6f6f6f !important;
    }
}

.iconInNavigate {
    color: #707070 !important;
}

.itemNavigate {
    font-weight: bold !important;
}

.active {
    color: #bd0e0e !important;
}