.containerEventosNa {
    max-height: 100vh !important; // O valor anterior era 100%
    overflow-y: scroll;
    width: 100% !important;
    background-color: #fff;
    // border: 2px solid rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(236, 236, 236, 0.2);
    border-radius: 4px;
    // box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.4);

    padding: 20px 20px 12px 20px !important;
    margin: 10px 0px 0px 0px !important;


    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

    overflow-y: scroll;

    scrollbar-width: thin;
    scrollbar-color: #e0e0e0 transparent;
    //&:hover {
    //    cursor: pointer;
    //    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    //}
}

.containerEventosNa::-webkit-scrollbar {
    width: 8px !important;
}

.containerEventosNa::-webkit-scrollbar-track {
    background-color: transparent !important;
}

.containerEventosNa::-webkit-scrollbar-thumb {
    background-color: #e0e0e0 !important;
    border-radius: 20px !important;
}

@media (max-width: 1024px) {
    .containerEventosNa {
        height: 50%;
        width: 100% !important;
        border: 2px solid rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.4);

        padding: 5px 0px 5px 5px !important;
        margin-top: 10px !important;
    }
}

.typographyPrimary {
    color: black !important;
    font-weight: bold !important;
    font-family: Roboto, "Helvetica", "Arial", sans-serif !important;
}

.typographyPrimary2 {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    // max-width: 100%;
    // text-overflow: ellipsis;
    // overflow: hidden;
    // white-space: nowrap;
}

.dadosEventosPublicacoes {
    display: flex !important;
    flex-direction: column !important;
}

@media (max-width: 1024px) {
    .internacionais {
        margin-left: 0px !important;
    }
}

.divtypographyEventoNa {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    width: 100% !important;
    height: 30px !important;

    padding: 12px;
    margin-bottom: 12px;
}

@media (max-width: 1024px) {
    .divtypographyEventoNa {
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        width: 100% !important;
        height: 60px !important;
    }
}

.typographyEventoNa {
    font-weight: bold !important;
    color: black !important;
}

@media (max-width: 1024px) {
    .typographyEventoNa {
        font-weight: bold !important;
        font-size: 20px !important;
        color: black !important;
    }
}

.subCategoria {
    font-weight: bold !important;
    color: black !important;
    font-size: 20px !important;
}


.listNa {
    max-height: 98% !important;
    width: 100%;
    overflow-y: scroll;
    scrollbar-width: thin !important;
    scrollbar-color: #707070 !important;

    &::-webkit-scrollbar {
        width: 0.8rem !important;
    }

    &::-webkit-scrollbar-track {
        background: transparent !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #707070 !important;
        border-radius: 0.4rem !important;
        border: 3px solid #f0f0f0 !important;
    }

}

@media (max-width: 1024px) {
    .listNa {
        margin-top: 10px;
        height: 90% !important;
        width: 100%;
        padding: 0px !important;
        overflow-y: scroll;
        scrollbar-width: thin !important;
        scrollbar-color: #707070 !important;

        &::-webkit-scrollbar {
            width: 0.8rem !important;
        }

        &::-webkit-scrollbar-track {
            background: transparent !important;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #707070 !important;
            border-radius: 0.4rem !important;
            border: 3px solid #f0f0f0 !important;
        }

    }
}


.listItemPrimaryNa {
    color: #bd0e0e;

    .divlistItemPrimaryNa {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 24px;
    }

}

.buttonAltDelete {
    display: flex;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
}

.typographyTituloNa {
    font-weight: bold !important;
    color: black !important;
}

.link {
    text-decoration: none;
}

.divlink:active {
    color: red !important;
}

.dadosEventosNa {
    display: flex !important;
    // flex-direction: row !important;
    margin-top: 10px !important;
}

.secondaryNa {
    justify-content: space-between;
}

.dadosIndividuaisNa {
    margin-left: 10px !important;
    margin-right: 0px !important;
}

.divPrimaryNa {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
    height: 24px !important;
}

.divEnderecoSecondaryNa {
    margin-top: 10px;
}

@media (max-width: 1250px) {
    .dadosIndividuaisNa {
        margin-left: 120px !important;
    }
}

@media (max-width: 1170px) {
    .dadosIndividuaisNa {
        margin-left: 100px !important;
    }
}

@media (max-width: 1125px) {
    .dadosIndividuaisIn {
        margin-left: 90px !important;
    }
}

@media (max-width: 1000px) {
    .dadosIndividuaisNa {
        margin-left: 80px !important;
    }
}

@media (max-width: 980px) {
    .dadosIndividuaisNa {
        margin-left: 40px !important;
    }
}

@media (max-width: 800px) {
    .listItemPrimary {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 0px 8px 16px !important;

        .listItemPrimaryNa {
            display: flex;
            flex-direction: column !important;
            justify-content: center;

        }

        .divlistItemPrimaryNa {
            display: flex;
            flex-direction: row !important;
            align-items: center;
            justify-content: space-between;
            // border: #bd0e0e solid;
            // height: 10vh;
        }

        .typographyPrimary {
            display: none;
        }

        .divPrimaryNa {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            // height: 50px !important;

            .divPrimary {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }


        .secondaryNa {
            display: flex;
            flex-direction: column !important;
        }

        .divSecondaryNa {
            margin: 0px 0px 10px 0px;
        }

        .divEnderecoSecondaryNa {
            margin: 0px;
        }
    }
}

@media (max-width: 600px) {
    .listItemPrimary {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 0px 8px 16px !important;

        .listItemPrimaryNa {
            display: flex;
            flex-direction: column !important;
            justify-content: center;

        }

        .divlistItemPrimaryNa {
            display: flex;
            flex-direction: column !important;
            justify-content: flex-start !important;
            align-items: flex-start !important;
            // border: #bd0e0e solid;
            height: 11vh !important;
            // margin: 5px 0px 10px 0px;

            .typographyPrimary2 {
                width: 100%;
                font-size: 16px !important;
            }
        }

        .typographyPrimary {
            display: none;
        }

        .divPrimaryNa {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            // height: 50px !important;

            .divPrimary {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }


        .secondaryNa {
            display: flex;
            flex-direction: column !important;
        }

        .divSecondaryNa {
            margin: 0px 0px 10px 0px;
        }

        .divEnderecoSecondaryNa {
            margin: 0px;
        }
    }

    .secondaryNa {
        display: flex !important;
        // align-items: center;
        // justify-content: center;
        flex-direction: column !important;
        gap: 10px;
    }
}


@media (max-width: 400px) {
    .typographyPrimary {
        display: none;
    }

    .divlistItemPrimaryNa {
        display: flex;
        flex-direction: column !important;
        justify-content: flex-start !important;
        align-items: flex-start !important;
        height: 11vh !important;
        width: 100%;
        height: 7vh !important;
        margin: 5px 0px 10px 0px;
    }

    .divTypographyPrimary {
        // border: #bd0e0e solid;
        width: 100% !important;
    }

    .typographyItemPrimaryNa {
        width: 100% !important;
        font-size: 16px !important;
    }

    .typographyPrimary2 {
        // border: #bd0e0e solid;
        width: 100% !important;
        font-size: 16px !important;
    }


    .divTypographyPrimary {
        // align-self: flex-start;
        width: 75%;
    }

    .buttonAltDelete {
        // align-self: flex-end;
        width: 20%;
    }
}