.containerView {
    min-height: 30px !important;
    max-height: 870px !important;
    width: 100% !important;

    background-color: #fff;
    border: 1px solid rgba(236, 236, 236, 0.2);
    border-radius: 4px;

    padding: 20px 20px 12px 20px !important;
    margin: 10px 0px 0px 0px !important;

    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24), 0 1px 2px rgba(0, 0, 0, 0.48);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

}

.container-view-perfil {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
    padding: 10px;

    .container-view-perfil_first-child {
        width: 48%;
        height: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
}

.box_view_perfil {
    height: 90vh;
    width: 100% !important;
    overflow-y: scroll;
    scrollbar-width: thin !important;
    scrollbar-color: #707070 !important;

    &::-webkit-scrollbar {
        width: 0.8rem !important;
    }

    &::-webkit-scrollbar-track {
        background: transparent !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #707070 !important;
        border-radius: 0.4rem !important;
        border: 3px solid #f0f0f0 !important;
    }
}

.infoViewPerfil {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    gap: 20px;

    .img_perfil_questions {
        width: 5rem !important;
        height: 5rem !important;

        h6 {
            font-size: 2rem !important;
        }
    }

    .name_studenty {
        font-size: 1rem !important;
        font-weight: bold !important;
    }

    .ra_studenty {
        font-size: 0.8rem !important;
        font-weight: bold !important;
        color: #6f6f6f !important;
    }
}


.head_response_filter {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    padding: 0.4rem !important;

    .title_select_filtro {
        color: #2c2c2c !important;
        font-weight: bold !important;
        font-size: 1.6rem !important;
    }

    .info_filter {
        padding: 0 0.8rem !important;
        margin-bottom: 0.6rem !important;
        font-weight: bold !important;
        color: #484848cc !important;
    }

    .filters_questions_in_respnse {
        padding: 0.4rem !important;
        display: flex !important;
        gap: 0.6rem !important;
    }
}

.nothing {
    width: 100% !important;
    margin-top: 2.8rem !important;

    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;

    .text_no_find {
        font-size: 1.4rem !important;
        font-weight: bold !important;
        color: #2c2c2ccd !important;
    }

    .subtext_no_find {
        font-size: 1rem !important;
        color: #9999997c !important;
    }
}

.questions_area_response {
    display: flex !important;
    flex-direction: column !important;
    padding: 0.6rem !important;
    margin: 0.6rem !important;
    border: 1px solid #ddd !important;
    border-radius: 4px !important;

    .question_author {
        display: flex !important;
        justify-content: flex-start !important;
        align-items: center !important;
        gap: 0.4rem !important;

        margin-bottom: 0.6rem !important;

        .author_name_course {
            display: flex !important;
            flex-direction: column !important;

            .author_name {
                font-size: 0.9rem !important;
                font-weight: bold !important;
                text-transform: capitalize !important;
            }

            .author_course {
                font-size: 0.8rem !important;
                color: #2c2c2cc7 !important;
            }
        }
    }

    .question_view {
        padding: 0.6rem !important;

        >h6 {
            font-family: "Roboto" !important;
            font-size: 1rem !important;
            font-weight: 400 !important;
        }
    }

    .question_view_actions {
        display: flex !important;
        justify-content: flex-end !important;
        align-items: center !important;
    }
}

.awaiting {
    margin-top: 36px;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 0.6rem !important;

    h6 {
        font-weight: bold !important;
        color: #999 !important;
    }
}

@media screen and (max-width: 840px) {
    .head_response_filter {
        .title_select_filtro {
            font-size: 1.2rem !important;
            margin-bottom: 1rem !important;
        }

        .filters_questions_in_respnse {
            display: flex !important;
            flex-direction: column !important;
            width: 100% !important;
            padding: 0 !important;
        }
    }

    .questions_area_response {
        margin: 0.2rem !important;

        .question_author {
            margin-bottom: 0 !important;

            .author_name_course {
                .author_name {
                    font-size: 0.8rem !important;
                }

                .author_course {
                    font-size: 0.7rem !important;
                }
            }
        }

        .question_view {
            padding: 0.4rem !important;

            >h6 {
                font-size: 0.8rem !important;
            }
        }

        .question_view_actions {
            button {
                font-size: 0.6rem !important;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .container-view-perfil {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        padding: 0px;

        .container-view-perfil_first-child {
            width: 100%;
            height: 100vh;
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    }

}