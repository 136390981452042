@mixin botao {
    color: #ffffff !important;
    text-transform: capitalize !important;
    font-size: 14px;
}

.botao-responder-pesquisa {
    @include botao;
}

.botao-escrever-depoimento {
    width: 260px;
    height: 40px !important;
    @include botao;
}

.seccao-depoimentos {
    display: flex;
    margin: 0 20px !important;
    padding: 30px 0;
    justify-content: center;    

    .box-card-aluno {
        min-width: 300px;
        max-width: 300px;
        margin-right: 40px;
    
        .card-aluno {
            margin: 0;
            position: relative;
            border: 1px solid rgba(0, 0, 0, 0.2);
            box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

            .box-content-card-aluno-lateral {
                padding: 10px 16px;

                h2 {
                    font-size: 24px;
                    margin-bottom: 10px;
                    font-weight: bold;
                }

                p {
                    text-align: justify;
                }
            }
    
            .box-botao {
                display: flex;
                flex-direction: row;
                justify-content: right;
                padding: 0 20px 10px 0;
            }
    
            .collapse {
                .link {
                    padding: 5px 10px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    font-family: 'Lato';
    
                    a {
                        display: block;
                        color: #000000;
                        text-decoration: none;
                    }
                }
            }
    
            #subtitulo01 {
                color: #bd0e0e;
                font-size: 14px;
            }
    
            #subtitulo02 {
                font-size: 13px;
            }
        }
    }
    
    .box-depoimentos {
        width: 1150px;
        border-radius: 5px;
        padding: 30px 0 !important;
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    
        .box-header {
            display: flex;
            flex-direction: column;
            width: 100% !important;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            margin-bottom: 40px;

            &::after {
                content: "";
                width: 70px;
                height: 5px;
                border-radius: 5px;
                background-color: #BD0E0E;
            }

        }
    
        h2 {
            font-size: 32px;
            padding: 0;
            text-align: center;
        }

        .box-text-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 30px;
        
            p {
                text-align: justify;
            }
        }
    }  
    
}

@media screen and (max-width: 1024px) {
    .seccao-depoimentos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 !important;

        .box-card-aluno {
            margin-right: 0px;
        }

        .box-depoimentos {
            width: 90%;
            margin-top: 40px;
            padding: 0;
            padding-top: 30px;

            .box-text-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0;
                margin: 0;
            
                p {
                    text-align: justify;
                    padding: 0 20px;
                    margin-bottom: 40px;
                    margin-right: 0px;
                }
            }    
        }


    }

    .botao-escrever-depoimento {
        width: 180px;
        margin-left: 0 !important;
    }
}

@media screen and (max-width: 480px) {
    .seccao-depoimentos {
        .box-depoimentos {
            width: 100%;
        }
    }
}