.iconButtonAppBar {
    border-radius: 36px !important;
    padding: 6px !important;
    font-size: 2rem !important;
}

.infoPerfil {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;

    .img_perfil_questions {
        width: 5rem !important;
        height: 5rem !important;

        h6 {
            font-size: 2rem !important;
        }
    }

    .name_studenty {
        font-size: 1rem !important;
        font-weight: bold !important;
    }

    .ra_studenty {
        font-size: 0.8rem !important;
        font-weight: bold !important;
        color: #6f6f6f !important;
    }
}

.iconInNavigate {
    color: #707070 !important;
}

.itemNavigate {
    font-weight: bold !important;
}

.navbar_question_answers {

    .box-arrow {
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .box-logo {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .box-pontuacoes {
        display: flex;
        flex-direction: row;
        align-items: center;
        // border: 1px solid black;

        .pontuacao {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-right: 20px;
            padding: 6px 10px;
            min-width: 5.7rem !important;
            max-width: 5.7rem !important;
            height: 35px;
            font-size: 16px;
            border-radius: 36px;
            color: #FFFFFF;

            &:last-child {
                margin-right: 0;
            }
        }
    }

}