.avatar_initials_name {
    background-color: #c7c7c7 !important;
    width: 3rem !important;
    height: 3rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 100% !important;


    h6 {
        font-family: "Roboto" !important;
        font-weight: bold !important;
        color: #fafafa !important;
    }
}