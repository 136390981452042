.containerBlocoNotasArquivos {
    min-height: auto;
    max-height: 120vh;
    overflow-y: scroll !important;
    scrollbar-width: thin !important;
    scrollbar-color: #A8A8A8 transparent !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.rowDisciplina {
    border: 1px solid black !important;
    width: 100% !important;
}

.containerBlocoNotasArquivos::-webkit-scrollbar {
    width: 8px !important;
}

.containerBlocoNotasArquivos::-webkit-scrollbar-track {
    background: transparent !important;
    margin: 20px !important;
}

.containerBlocoNotasArquivos::-webkit-scrollbar-thumb {
    background-color: #A8A8A8 !important;
    border-radius: 20px !important;
}

td {
    a {
        text-decoration: none;
        text-transform: uppercase;
    }

    h6 {
        font-size: 14px;
    }
}

@media screen and (max-width: 960px){

    .rowDisciplina td, .rowDisciplina button {
        font-size: 10px;
    }

    .rowUnidadesDisciplina td {
        font-size: 10px;
    }

}