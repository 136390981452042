@supports (display: grid) {
    .painting-portfolio {
        margin-bottom: 10vh;
        padding: 12px;
        
        display: grid;
        grid-gap: 12px;
        grid-template-columns: repeat(9, 1fr);
        grid-template-rows: minmax(auto, 20vh);
    }    

    .profile-section {
        grid-column-start: 2;
        grid-column-end: 6;
    }

    .work-section {
        grid-column-start: 6;
        grid-column-end: 10;
    }

    .chart-section {

        // height: 50vh;

        grid-column-start: 2;
        grid-column-end: 10;

        grid-row-start: 2;
        grid-row-end: 4;
    }

    .professional-section {
        // min-height: 60vh;
        grid-column-start: 2;
        grid-column-end: 10;

        grid-row-start: 4;
        grid-row-end: 5;
    }



    .painting-banner {

        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 5;    
        // min-height: 100vh;    

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        img {
            width: 50%;
            margin: 12px;
        }

        .banner-text {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            margin-top: 10vh;
            padding: 12px;

            .text {
                color: white;
                writing-mode: vertical-rl;
                transform: rotate(180deg);
            }
        }
    }
}


.painting-portfolio {
    background-color: rgba(250, 247, 255, 0.52);
}

.painting-portfolio > div {
    border-radius: 4px;
    // min-height: 30vh;
}

.painting-portfolio > .regular {
    background-color: rgb(255, 255, 255);    
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
}

.profile-section {
    min-height: 40vh;
}

@media screen and(max-width: 960px) {
    @supports (display: grid) {
        .painting-portfolio {
            display: grid;
            grid-gap: 12px;
            grid-template-columns: repeat(6, 1fr);
            grid-template-rows: minmax(200px, auto);        
        }

        .profile-section {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 2;            
        }
    
        .work-section {
            grid-column-start: 3;
            grid-column-end: 7;
            grid-row-start: 1;
            grid-row-end: 2;
        }
    
        .chart-section {
            grid-column-start: 1;
            grid-column-end: -1;    
            grid-row-start: 2;
            grid-row-end: 4;
        }
    
        .professional-section {            
            grid-column-start: 1;
            grid-column-end: -1;    
            grid-row-start: 4;
            grid-row-end: 5;
        }

        .painting-banner {
            display: none !important;            
        }

        .painting-portfolio .regular {
            min-height: 200px;
            background-color: rgb(255, 255, 255);
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        
            // border: solid 1px rgb(104, 36, 194);
        }
    }
}


.profile-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
}

.profile-full {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: minmax(100px, auto);
    

    margin-top: 24px;
    margin-bottom: 24px;

    & > div {
        border-radius: 4px;        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 50px;
    }

    .profile-pic {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
    }

    .profile-info {
        grid-column-start: 2;
        grid-column-end: -1;
        grid-row-start: 1;
        grid-row-end: 2;
    }

    .profile-pic, 
    .profile-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        // border: solid purple;
    }
}