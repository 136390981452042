.learning_resources {
    border: 1px solid #d0d0d0 !important;
    border-radius: 8px !important;
    padding: 8px !important;
    padding: 12px 24px !important;

    // -- Lucas Was Here

    //background-color: #f8f8f8;
    // margin: 2.4rem 0 !important;
    // width: 100% !important;

    .header {
        width: 100% !important;
        margin-bottom: 1rem !important;

        .area_close {
            flex: 1 !important;
            border-radius: 8px !important;
            height: 100% !important;

            // .iconClose {
            //     // color: #BD0E0E !important;
            //     // font-size: 1.8rem !important;
            // }
        }

        .area_title_learning_resourses {
            flex: 4 !important;

            &> :first-child {
                font-size: 1.6rem !important;
                font-weight: 700 !important;
                text-transform: uppercase !important;
                text-align: center !important;
                margin-bottom: 0.8rem;
            }

            &> :last-child {
                font-size: 1rem !important;
                text-align: center !important;
                color: #565656 !important;
            }
        }

        .area_buttons {
            flex: 1 !important;

            &>button {
                margin-bottom: 0.8rem !important;
            }
        }
    }
}

@media (max-width: 840px) {
    .learning_resources {
        border: none !important;
        border-radius: none !important;
        padding: 4px !important;
        margin: 0px !important;

        .header {
            .area_close {
                border-radius: 4px !important;

                .iconClose {
                    font-size: 1.6rem !important;
                }
            }

            .area_title_learning_resourses {
                &> :first-child {
                    font-size: 1rem !important;
                    margin-bottom: 0.6rem;
                }

                &> :last-child {
                    font-size: 0.8rem !important;
                }
            }

            .area_buttons {
                align-items: flex-end !important;

                &>button {
                    margin-bottom: 0.2rem !important;
                    font-size: 0.6rem !important;
                    width: auto !important;
                }
            }
        }
    }
}

.slideResources {
    display: flex !important;
    align-items: center !important;

    margin-top: 1.2rem !important;

    width: 100% !important;
    position: relative !important;

    >.slide {
        display: flex !important;
        margin-left: 1.2rem !important;

        border-bottom: 1px dashed #cbcbcb !important;

        //   width: 100% !important;
        overflow-x: auto !important;
        scroll-behavior: smooth !important;

        width: 100000px;
        height: 400px;

        &::-webkit-scrollbar {
            width: 0.8rem !important;
        }
    
        &::-webkit-scrollbar-track {
            background: transparent !important;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: #a1a0a0 !important;
            border-radius: 0.4rem !important;
            border: 3px solid #f0f0f0 !important;
        }
    }

    >.btn_slide_right,
    .btn_slide_left {
        width: 2rem;
        height: 2rem;
        color: #6c757d;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        margin: 0.5rem;
    }
}

@media screen and (max-width: 840px) {
    .slideResources {
        margin-top: 0 !important;

        >.slide {
            margin-left: 0 !important;

            padding: 0.8rem 1rem !important;

            max-width: 100% !important;
            overflow-x: auto !important;
            scroll-behavior: smooth !important;
        }

        >.btn_slide_right,
        .btn_slide_left {
            display: none !important;
        }
    }
}