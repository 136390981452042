button { 
    border: none !important;
    padding: 12px 24px;
}

.body {
    min-height: unset !important;
}

// .chips { 
//     position: relative;
    
//     max-width: 230px;

//     display: flex;

//     align-items: center;
//     justify-content: center;

//     .popover { 
//         padding: 12px 24px;
//         background-color: #333;
//         color: white;
//         border-radius: 12px;
//         position: absolute;
//         bottom: -40px;

//         span {
//             font-size: 0.8em;
//         }        
//     }

//     .hide{ 
//         display: none;
//     }
// }

