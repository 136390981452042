.card { 
    min-width: 280px;
    min-height: 350px;
    margin: 12px;
    
    cursor: pointer;
    display: flex;    
    flex-direction: column;
    
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(126, 125, 125, 0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);

    overflow: hidden;

    img {
        height: 20%;
    }
    
    div { 
        text-align: center;        
    }

    hr { 
        border: solid thin #ddd;
    }
}
@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}
.linear-background {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 1000px 104px;
    height: 338px;
    position: relative;
    overflow: hidden;

}

.cover { 
    width: 100%;
    height: 80px;
}

.item {     
    height: 30px;
    margin: 12px;
    border-radius: 4px;
}
