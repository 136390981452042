.area_header_dialog {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 3rem 0 2rem 0 !important;

    & > :first-child {
        text-align: center !important;
        border-radius: 9999px !important;
        color: #fff !important;
        padding: 1rem !important;
        font-size: 5rem !important;
        margin-bottom: 0.6rem !important;
    }

    & > :last-child {
        font-size: 1.6rem !important;
        font-family: "Roboto" !important;
        font-weight: 500 !important;
    }

    &::after {
        content: "";
        width: 2rem !important;
        height: 0.2rem !important;
        background-color: #ffc000 !important;
    }
}

@media screen and (max-width: 840px) {
    .area_header_dialog {
        & > :first-child {
            padding: 0.6rem !important;
            font-size: 3rem !important;
            margin-bottom: 0.4rem !important;
        }
    
        & > :last-child {
            font-size: 1.2rem !important;
        }
    
        &::after {
            height: 0.1rem !important;
        }
    }
}