.dialog_title {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    padding: 0.4rem !important;
}

.text_dialog_title {
    text-align: center !important;
    font-weight: bold !important;
    font-size: 1.8rem !important;
    color: #fff !important;
}

.line_record_item {
    display: flex !important;
    align-items: center !important;

    border: 1px solid #a7a7a7cf !important;
    border-radius: 4px !important;
    padding: 4px !important;
    background-color: #fefefe !important;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: filter 0.2s !important;
    
    & + .line_record_item {
        margin-top: 4px !important;
    }
    
    .text_subject {
        max-width: 100% !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;

        font-family: 'Lato' !important;
        font-size: 0.8rem !important;
        font-weight: 700 !important;
    }

    &:hover {
        filter: brightness(0.9) !important;
    }
}

.area_date {
    border: 1px solid #707070 !important;
    border-radius: 4px !important;
    padding: 2px !important;
    color: #707070 !important;
    text-align: center !important;

    h6 {
        font-size: 0.7rem !important;
        font-weight: bold !important;
    }

    &.finish {
        > h6 {
            font-size: 0.6rem !important;
        }

        margin-right: 2px !important;
        background-color: #429a25 !important;
        color: #fff !important;
    }
    
    &.cancel {
        > h6 {
            font-size: 0.6rem !important;
        }

        margin-right: 2px !important;
        background-color: #aa1122 !important;
        color: #fff !important;
    }
    
    &.pendent {
        > h6 {
            font-size: 0.6rem !important;
        }

        margin-right: 2px !important;
        background-color: #346599 !important;
        color: #fff !important;
    }
}

@media (max-width: 840px) {
    .text_dialog_title {
        font-size: 1rem !important;
    }
}