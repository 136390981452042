.containerEstagioProjetosNotas{
    display: flex !important;
    flex-direction: row !important;
    height: 50% !important;
    padding: 0px !important;    
}

@media (max-width: 1024px){
    .containerEstagioProjetosNotas{
        display: flex !important;
        flex-direction: column !important;
        height: 100% !important;
    }
}