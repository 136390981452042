@mixin styleBotao { 
    color: #ffffff !important;
    text-transform: capitalize !important;
    font-size: 14px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.seccao-oficina-curriculo {
    display: flex;
    margin: 40px 20px 0 20px;
    justify-content: center;

    .box-card-mentoria-lateral {
        min-width: 300px;
        max-width: 300px;
        margin-right: 40px;
    
        .card-mentoria-lateral {
            margin: 0;
            border: 1px solid rgba(0, 0, 0, 0.2);
            box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

            .box-header {
                padding: 32px 32px 16px 0;

                span {
                    display: flex !important;
                    flex-direction: row;
                    justify-content: right !important;
                    align-items: center;
                    color: #000000;

                    #subtitulo {
                        height: 100%;
                        margin-right: 5px;
                        font-size: 14px;
                    }
                }
            }

            .box-botao-card-mentoria-lateral {
                display: flex;
                flex-direction: row;
                justify-content: right;
                padding: 0 20px 20px 0;

                .botao {
                    @include styleBotao;
                }
            }
    
            h2 {
                font-size: 24px;
                margin-bottom: 10px;
                font-weight: bold;
            }

            p {
                text-align: justify;
            }
        }
    }
    
    .box-oficina-curriculo {
        width: 1000px;
        padding-top: 20px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    
        .box-header {
            display: flex;
            flex-direction: column;
            width: 100% !important;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;

            &::after {
                content: "";
                width: 70px;
                height: 5px;
                border-radius: 5px;
                background-color: #BD0E0E;
            }

            h2 {
                font-size: 32px;
                padding: 0;
                text-align: center;
            }
        }
    
        .box-content {
            display: flex;
            flex-direction: row;
            justify-content: center;
            height: 412px;
            margin: 20px 0;
            padding: 0 20px;

            .box-card-mentoria {
                max-width: 350px !important;
                margin-right: 40px;

                &:last-child {
                    margin-right: 0;
                }
            
                .card-mentoria {
                    margin: 0;
                    padding-bottom: 20px;
                    position: relative;
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

                    .box-content-card-mentoria {
                        padding: 10px 16px;
                    }
        
                    .box-botao-card-mentoria {
                        display: flex;
                        flex-direction: row;
                        justify-content: right;
                        padding: 0 20px 0 0;

                        .botao {
                            @include styleBotao;
                        }
                    }
            
                    h2 {
                        font-size: 24px;
                        height: 65px;
                        margin-bottom: 10px;
                        font-weight: bold;
                    }
        
                    p {
                        text-align: justify;
                    }
                }
            }
        }
    }    
}

@media screen and (max-width: 1024px) {
    .seccao-oficina-curriculo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;
        margin-top: 40px !important;

        .box-card-mentoria-lateral {
            margin-right: 0;
        
            .card-mentoria-lateral {
                margin: 0;
                border: 1px solid rgba(0, 0, 0, 0.2);
                box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

                .box-content-card-mentoria-lateral {
                    padding: 10px 16px;
                }
    
                p {
                    text-align: justify;
                }
            }
        }

        .box-oficina-curriculo {
            width: 90%;
            padding: 30px 0;
            margin-top: 40px;

            .box-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: auto;
                padding: 0;
                padding-top: 30px;
                margin: 0;

                .box-card-mentoria {
                    max-width: 80% !important;
                    margin-right: 0;  
                    
                    &:first-child {
                        margin-bottom: 30px;
                    }

                    .card-mentoria {
                        margin: 0;
                        height: 100%;
                        position: relative;
                        border: 1px solid rgba(0, 0, 0, 0.2);
                        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    
                        .box-content-card-mentoria {
                            padding: 10px 16px;
                        }
            
                        .box-botao-card-mentoria {
                            display: flex;
                            flex-direction: row;
                            justify-content: right;
                        }
                
                        h2 {
                            font-size: 24px;
                            height: auto;
                            margin-bottom: 10px;
                            font-weight: bold;
                        }
            
                        p {
                            text-align: justify;
                        }
                    }
                }
            
            }    
        }

    }
}

@media screen and (max-width: 480px) {
    .seccao-oficina-curriculo {
        .box-oficina-curriculo {
            width: 100%;
        }
    }
}