.container {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;

    background-color: #fafafa !important;

    > h3 {
        font-family: "Roboto" !important;
        color: #2c2c2c !important;
    }

    .error_action {
        display: flex !important;
        flex-direction: column !important;
        justify-content: space-around !important;
        align-items: center !important;
        
        margin: 0.6rem 0 !important;
        border-radius: 4px !important;
        padding: 0.6rem !important;
        width: 12rem !important;
        background-color: #f0f0f0 !important;

        p {
            font-family: "Roboto" !important;
            font-weight: 500 !important;
            font-size: 1.4rem !important;

            display: flex !important;
            flex-direction: column !important;
            justify-content: center !important;
            align-items: center !important;

            &::after {
                content: "" !important;
                width: 18% !important;
                height: 2px !important;
                background-color: #bd0e0e !important;
                margin: 0.6rem 0 !important;
            }
        }
        
        > button {
            background-color: transparent !important;
            cursor: pointer !important;
            text-align: center !important;
            border: 1px solid #2c2c2c7d !important;
            font-family: "Roboto" !important;
            font-weight: 500 !important;
            color: #2c2c2c !important;
            border-radius: 36px !important;
        }
    }
}