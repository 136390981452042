.line_item {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;

    border: 1px solid #25ac97 !important;
    border-radius: 4px !important;
    padding: 8px !important;

    // Temporario
    background-color: #efefef !important;

    .file_info {
        display: flex !important;
        align-items: center !important;

        .preview {
            width: 3.2rem !important;
            height: 3.2rem !important;
            border-radius: 4px !important;
            margin-right: 10px !important;
        }

        .area_name_file {
            word-wrap: break-word !important;

            .name_file {
                font-size: 1rem !important;
                font-weight: bold !important;
                font-family: 'Roboto' !important;
                color: #2c2c2c !important;
            }
    
            .length_file {
                font-weight: bold !important;
                font-size: 0.8rem !important;
                font-family: 'Roboto' !important;
                color: #25ac97 !important;
            }
        }
    }

    .iconTrash {
        background-color: #25ac97 !important;
        border-radius: 4px !important;

        & > span {
            color: #fff !important;
        }
    }
}

@media screen and (max-width: 1024px) {
    .line_item {
        flex-wrap: wrap !important;

        .file_info {
            max-width: 80% !important;
    
            .area_name_file {
                word-wrap: break-word !important;
                max-width: 60% !important;
            }
        }
    }
}