.form_review_question {
    width: 100% !important;
    border: 1px solid #2c2c2c7d;
    border-radius: 4px !important;
    background-color: #f8f8f8 !important;
    padding: 8px !important;
    margin: 12px 0 !important;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

    & > div {
        padding: 4px !important;
    }
}

.btn-hidden {
    opacity: 0 !important;
}