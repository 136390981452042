.card-vaga { 
    max-width: 100% !important;
    margin-right: 20px !important;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

    .box-image {
        max-width: 100% !important;

        img {
            max-width: 100% !important;
        }
    }

    .box-content {
        padding: 0;

        h2 {
            font-size: 21px;
            text-align: left !important;
            padding: 20px 0 10px 20px;
            font-weight: 500;
        }
    
        p {
            padding: 10px 20px 10px 20px;
            text-align: justify;
        }

        span {
            padding: 10px 0 10px 20px;
            text-align: left;
        }
    }

    .box-botoes {
        max-width: 100% !important;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 20px 20px 20px;

        .botao-detalhes {
            background-color: #007ad9;
            width: 100px;
            height: 30px; 
            color: #ffffff !important;
            text-transform: capitalize !important;
            font-size: 14px;
        }

        .botao-candidatar {
            width: 110px;
            height: 30px; 
            color: #ffffff !important;
            text-transform: capitalize !important;
            font-size: 14px;
        }
    }
} 

@media screen and (max-width: 768px) {
    .card-vaga {
        width: 100% !important;
    }
}

@media screen and (max-width: 480px) {
    .card-vaga {
        .box-content {
            padding: 0;
    
            h2 {
                font-size: 21px;
                text-align: center !important;
                padding: 20px 0 10px 0;
                font-weight: 500;
            }
        
            p {
                padding: 10px 20px 10px 20px;
                text-align: justify;
            }
    
            span {
                display: block;
                padding: 10px 0 10px 0;
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: 320px) {
    .card-vaga {

        .box-content {
            padding: 0;
    
            h2 {
                font-size: 21px;
                text-align: center !important;
                padding: 20px 0 10px 0;
                font-weight: 500;
            }
        
            p {
                padding: 10px;
                text-align: justify;
            }
    
            span {
                display: block;
                padding: 10px 0 10px 0;
                text-align: center;
            }
        }

        .box-botoes {            
            padding: 10px 10px 20px 10px;
    
            .botao-detalhes {
                font-size: 12px;
            }
    
            .botao-candidatar {
                font-size: 12px;
            }
        }
    }
}

