.btn_move_scroll_carousel {
    border: solid thin #ddd !important;
    background-color: #f5f5f5 !important;
}

.row { 
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;           
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%; 
    
    overflow-x: scroll;   
    overflow-y: visible;
    -ms-overflow-style: none;  /* IE and Edge */    
    -moz-scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }    

    
    scroll-behavior: smooth;
    scroll-snap-type: x proximity;

    
    // * {
    //     margin: 12px;
    // }
}

@media (max-width: 840px) {
    .btn_move_scroll_carousel {
        display: none !important;
    }
    .row {
        &::-webkit-scrollbar {
            display: flex !important;
            height: 5px;
        }
          
        /* Track */
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey; 
            border-radius: 10px;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #545454; 
            border-radius: 10px;
        }
    }
}