.content {
    border: 1px solid #dbdbdb !important;
    border-radius: 4px !important;
    padding: 0.6rem !important;
    width: 100% !important;
    border: 1px solid #ffd0d086 !important;
    
    & + & {
        margin-top: 0.6rem !important;
    }
}