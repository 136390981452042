.welcome {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 2rem 0px !important;
  margin-top: 5vh !important;
  margin-bottom: 5vh !important;

  h3 span {
    font-family: "Roboto", sans-serif !important;
  }

  // span {
  //   color: #bd0e0e !important;
  // }

  .text_welcome {
    font-size: 1.6rem !important;
    margin-bottom: 1.2rem !important;
  }

  .text_application {
    font-size: 2.2rem !important;
    font-weight: 700 !important;
    text-align: center !important;
  }

  .text_subtitle {
    font-size: 1.2rem !important;
    color: #494747 !important;
    text-align: center !important;
  }
}

@media (max-width: 840px) {
  .welcome {
    margin: 0.8rem 0px !important;

    .text_welcome {
      font-size: 1rem !important;
    }

    .text_application {
      font-size: 1.4rem !important;
      font-weight: 700 !important;
    }

    .text_subtitle {
      font-size: 0.8rem !important;
      color: #494747 !important;
    }
  }
}
