.area_title {
    background-color: #fafafa !important;
    border: 1px solid #6e6e6e7d !important;
    border-radius: 4px !important;
    padding: 0.6rem !important;
    margin: 1rem 0 !important;

    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

    .title {
        color: #2c2c2c !important;
        font-family: "Roboto" !important;
        font-weight: 500 !important;
        font-size: 2.2rem !important;
        text-transform: uppercase !important;
    }
}

.filters_header {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;

    margin-bottom: 0.6rem !important;
}

.tag_situation {
    background-color: #989898 !important;
    color: #fff !important;
    padding: 4px 6px !important;
    font-size: 0.8rem !important;
    border-radius: 4px !important;
    text-align: center !important;
    text-transform: uppercase !important;
    font-weight: bold !important;

    &.deferred {
        background-color: #13930ae0 !important;
    }

    &.rejected {
        background-color: #bd0e0e !important;
    }
}

.divider_filter {
    border: 1px solid #bebcbc !important;
    margin: 0 0.8rem !important;
    width: 0.1rem !important;
    height: 1.4rem !important;
    background-color: #bebcbc !important;
}

.item_menu_course {
    text-align: justify !important;
    text-transform: capitalize !important;
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
    color: #2c2c2c !important;

    &.selected {
        > svg {
            color: #bd0e0e !important;    
        }

        color: #bd0e0e !important;
    }
}

@media screen and (max-width: 840px) {
    .filters_header {
        flex-direction: column !important;
        align-items: flex-end !important;
    
        > div {
            border: none !important;
        }
    }

    .divider_filter {
        display: none !important;
    }
}