.card-trilha-aprendizagem { 
    max-width: 100% !important;
    min-height: 380px;
    border-radius: 5px;
    margin-right: 20px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

    .box-image {
        max-width: 100% !important;

        img {
            max-width: 100% !important;
        }
    }

    .box-content {

        padding: 20px 0 0 20px;

        .box-azul {
            display: flex;
            flex-direction: column;
            justify-content: center !important;
            align-items: center !important;
            width: 120px;
            height: 100px;
            background-color: #007ad9;
            border-radius: 5px;

            span {
                text-align: center;
                color: #ffffff;
                font-size: 14px;
            }
        }
        
        h2 {
            max-width: 100% !important;
            min-height: 90px !important;
            font-size: 21px;
            text-align: left !important;
            font-weight: 500;
            padding-top: 20px;
        }

    }

    .box-botao-acessar {
        max-width: 100% !important;
        display: flex;
        flex-direction: row;
        justify-content: right;
        padding-right: 20px;
        margin-bottom: 20px;

        .botao-acessar {
            width: 80px;
            height: 30px; 
            color: #FFFFFF !important;
            text-transform: capitalize !important;
            font-size: 14px;
        }
    }
    
}


@media screen and (max-width: 768px) {
    .card-trilha-aprendizagem {
        width: 100% !important;
    }
}


@media screen and (max-width: 520px) {
    .card-trilha-aprendizagem {   

        .box-content {

            padding: 20px 20px 0 20px;

            .box-azul {
                margin: 0 auto;
            }
    
            h2 {
                min-height: 77px !important;
                margin-bottom: 20px;
                font-size: 19px;
                text-align: center !important;
                padding-left: 0;
            }
    
        }        
    
        .box-botao-acessar {
            justify-content: center;
            padding: 0;
        }
        
    } 
}