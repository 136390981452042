.header_page {
    width: 100% !important;
    margin-bottom: 60px;
    
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;

    .iconSchool {
        font-size: 5.2rem !important;
        color: #FFF !important;
        border-radius: 9999px !important;
        padding: 8px !important;
        // margin-top: .2rem;
        margin-bottom: 1.2rem;
    }

    .title_page {
        font-size: 3.6rem !important;
        font-weight: 500 !important;
        text-transform: uppercase !important;
        font-family: 'Roboto' !important;
        letter-spacing: 0.2rem !important;
        line-height: 1.8ch;
        color: #2c2c2c !important;
        // margin-bottom: 12px;
    }

    .description_page {
        margin-top: 1.7rem;
        text-align: center !important;
        font-size: 1.4rem !important;
        color: #3b3b3b;
        font-weight: 400;
    }
}

@media screen and (max-width: 840px) {
    .header_page {
    
        .iconSchool {
            // font-size: 2.8rem !important;
            font-size: 5.2rem !important;
        }
    
        .title_page {
            font-size: 1.6rem !important;
            font-weight: 500 !important;
            text-transform: uppercase !important;
            font-family: 'Roboto' !important;
            letter-spacing: 0.2rem !important;
        }
    
        .description_page {
            text-align: center !important;
            font-size: 1rem !important;
            color: #252525;
        }
    }
}