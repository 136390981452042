.containerEventosIn {
    min-height: 320px !important;
    width: 100% !important;
    background-color: #fff;
    // border: 2px solid rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(236, 236, 236, 0.2);
    border-radius: 4px;
    // box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.4);

    padding: 20px 20px 12px 20px !important;
    margin: 10px 0px 0px 0px !important;

    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

    //&:hover {
    //    cursor: pointer;
    //    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    //}
}

@media (max-width: 1024px) {
    .containerEventosIn {
        height: 50%;
        width: 100% !important;
        border: 2px solid rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.4);

        padding: 5px 0px 5px 5px !important;
        margin-top: 10px !important;
    }
}

@media (max-width: 1024px) {
    .internacionais {
        margin-left: 0px !important;
    }
}

.divtypographyEventoIn {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    width: 100% !important;
    height: 30px !important;

    padding: 12px;
}

@media (max-width: 1024px) {
    .divtypographyEventoIn {
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        width: 100% !important;
        height: 60px !important;
    }
}

.typographyEventoIn {
    font-weight: bold !important;
    color: black !important;
}

@media (max-width: 1024px) {
    .typographyEventoIn {
        font-weight: bold !important;
        font-size: 20px !important;
        color: black !important;
    }
}

.listIn {
    max-height: 100%;
    width: 100%;
    overflow-y: scroll;
    scrollbar-width: thin !important;
    scrollbar-color: #707070 !important;

    &::-webkit-scrollbar {
        width: 0.8rem !important;
    }

    &::-webkit-scrollbar-track {
        background: transparent !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #707070 !important;
        border-radius: 0.4rem !important;
        border: 3px solid #f0f0f0 !important;
    }

}

@media (max-width: 1024px) {
    .listIn {
        margin-top: 10px;
        height: 90% !important;
        width: 100%;
        overflow-y: scroll;
        scrollbar-width: thin !important;
        scrollbar-color: #707070 !important;

        &::-webkit-scrollbar {
            width: 0.8rem !important;
        }

        &::-webkit-scrollbar-track {
            background: transparent !important;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #707070 !important;
            border-radius: 0.4rem !important;
            border: 3px solid #f0f0f0 !important;
        }

    }
}


.listItemPrimaryIn {
    color: #bd0e0e;
}

.typographyTituloIn {
    font-weight: bold !important;
    color: black !important;
}

.divlink:active {
    color: red !important;
}

.dadosEventosIn {
    display: flex !important;
    flex-direction: row !important;
    margin-top: 10px !important;
}

.secondaryIn {
    justify-content: space-between;
}

.dadosIndividuaisIn {
    margin-left: 10px !important;
    margin-right: 0px !important;
}

@media (max-width: 1250px) {
    .dadosIndividuaisIn {
        margin-left: 120px !important;
    }
}

@media (max-width: 1170px) {
    .dadosIndividuaisIn {
        margin-left: 100px !important;
    }
}

@media (max-width: 1125px) {
    .dadosIndividuaisIn {
        margin-left: 90px !important;
    }
}

@media (max-width: 1000px) {
    .dadosIndividuaisIn {
        margin-left: 80px !important;
    }
}

@media (max-width: 980px) {
    .dadosIndividuaisIn {
        margin-left: 40px !important;
    }
}

@media (max-width: 800px) {
    .dadosEventosIn {
        display: flex !important;
        margin-top: 10px !important;
        flex-direction: column !important;
    }

    .dadosIndividuaisIn {
        margin-left: 0px !important;
    }
}