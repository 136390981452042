.title_card {
    font-size: 1.6rem !important;
    font-weight: 700 !important;
    text-align: center !important;
    color: #2c2c2c !important;
}

.btnAcesss {
    color: #FFF !important;
}

@media screen and (max-width: 840px) {
    .title_card {
        font-size: 1.2rem !important;
    }

    .btnAcesss {
        font-size: 0.8rem !important;
    }
}