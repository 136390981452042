.dialog-tutorial-mobile {
	border: none;
	// .container-carousel {
	// 	max-width: 100% !important;
	// 	max-height: 100% !important;
	// 	display: flex;
	// 	flex-direction: column;
	// 	justify-content: stretch;
	// 	align-items: center;
	// 	border: 1px solid red;

	// 	.p-carousel-container {
	// 		max-width: 100% !important;
	// 		max-height: 100% !important;
	// 	}

	// 	.carousel-etapas-tutorial-mobile {
	// 		border: 1px solid black;
	// 		max-width: 100% !important;
	// 		max-height: 100% !important;
	// 	}
	// }
}
