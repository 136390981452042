* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: #fff !important;
    overflow-y: scroll;

    scrollbar-width: thin;
    scrollbar-color: #A8A8A8 transparent;
}

body::-webkit-scrollbar {
    width: 10px !important;
}

body::-webkit-scrollbar-track {
    background: transparent !important;
    margin: 20px !important;
}

body::-webkit-scrollbar-thumb {
    background-color: #A8A8A8 !important;
    border-radius: 20px !important;
}

p, h1, h2, h3, h4, h5, h6, button {
    font-family: 'Lato' !important;
}

.logo {
    width: 8.8rem;
}

/* DataTableDemo.css */
.datatable-responsive-demo .p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-thead > tr > th,
    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4em 1em -.4em -.4rem;
        font-weight: bold;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td:last-child {
        border-bottom: 1px solid var(--surface-d);
    }
}
/* End DataTableDemo.css */