.boxDetail {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    
    border-radius: 4px !important;
    background-color: #ffeaea57 !important;

    padding: 0.6rem !important;

    transition: filter 0.2s !important;

    &:hover {
        cursor: pointer !important;
        filter: brightness(0.8) !important;
    }

    &.isHeader {
        background-color: #fafafab4 !important;
    }

    > svg {
        color: #bd0e0e !important;
    }

    .infos {
        display: flex !important;
        flex-direction: column !important;

        margin-left: 0.8rem !important;
        padding: 0 0.4rem !important;

        border-left: 1px solid #ff8585a2 !important;

        .info_title {
            color: #2c2c2c !important;
            font-weight: bold !important;
            font-size: 0.9rem !important;
            font-family: "Roboto" !important;
        }

        .info_value {
            color: #2c2c2caa !important;
            font-weight: 500 !important;
            font-size: 0.9rem !important;
            font-family: "Roboto" !important;
        }

    }

}

.isCopy {
    font-weight: bold !important;
    font-size: 0.7rem !important;
    color: #2c2c2caa !important;
    padding-left: 0.4rem !important;

    &.isHeader {
        color: #fafafa !important;
    }

    &.isCopied {
        color: #afaa00 !important;
    }
}