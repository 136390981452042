.navbar_question_answers {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    width: 100% !important;
}

.iconButtonAppBar {
    border-radius: 36px !important;
    padding: 6px !important;
    font-size: 2rem !important;
}

.infoPerfil {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;

    .img_perfil_schedule {
        width: 4rem !important;
        height: 4rem !important;
        
        h6 {
            font-size: 1.4rem !important;
        }
    }

    .name_studenty {
        font-size: 1rem !important;
        font-weight: bold !important;
    }

    .ra_studenty {
        font-size: 0.8rem !important;
        font-weight: bold !important;
        color: #6f6f6f !important;
    }
}

.iconInNavigate {
    color: #707070 !important;
}

.itemNavigate {
    font-weight: bold !important;
}

.active {
    color: #bd0e0e !important;
}

.title {
    display: flex !important;
    align-items: center !important;

    padding: 8px !important;
    color: #2c2c2ce6 !important;
    font-weight: bold !important;
    font-size: 14px !important;
    text-align: center !important;

    &::before {
        content: "" !important;
        flex: 1 !important;
        height: 1px !important;
        background-color: #FFC000 !important;
        margin-right: 4px !important;
    }

    &::after {
        content: "" !important;
        flex: 1 !important;
        height: 1px !important;
        background-color: #FFC000 !important;
    }
}

.sub_area {
    display: flex !important;
    justify-content: space-around !important;
    align-items: center !important;

    .numSolicitations {
        display: flex !important;
        justify-content: flex-start !important;
        align-items: center !important;
    
        margin-left: 8px !important;
        padding: 4px !important;
        font-size: 0.8rem !important;
        font-weight: bold !important;
        color: #2c2c2cdf !important;
    }

    > button {
        font-size: 0.7rem !important;
        font-weight: bold !important;
        color: #2c2c2cdf !important;
        border-color: #2c2c2cdf !important;
    }
}

.nullValue {
    text-align: center !important;
    font-weight: bold !important;
    color: #a7a7a7 !important;
    padding: 8px 0 !important;
}

.menu_item {
    display: flex !important;
    align-items: center !important;
    max-width: 90vw !important;

    & > svg {
        margin-right: 4px !important;
    }

    & > .item {
        max-width: 90vw !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;

        padding: 4px !important;
        color: #444444 !important;
        font-weight: bold !important;
        font-size: 14px !important;
    }
}

@media screen and (max-width: 840px) {
    .iconButtonAppBar {
        font-size: 1.6rem !important;
        padding: 4px !important;
    }
}