.body_report {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    
    .title_report {
        color: #bd0e0e !important;
        font-weight: bold !important;
        font-size: 1.2rem !important;
        margin-bottom: 0.4rem !important;
    }

    .subtitle_report {
        color: #2c2c2cc8 !important;
        font-weight: bold !important;
        font-size: 1rem !important;
        margin-bottom: 0.4rem !important;
    }
}

.footer_report {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

    > button {
        font-weight: bold !important;
    }

    > button.btn_outlined {
        border: 1px solid #ffc000 !important;
        border-radius: 4px !important;
    }
}