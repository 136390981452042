.navbar_question_answers {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.area_list_questions {
    padding-left: 240px !important;
    padding-right: 0px !important;
}

@media screen and (max-width: 1280px) {
    .area_list_questions {
        padding-left: 0 !important;
    }
}