.lista-depoimentos {
    margin-top: 40px;
    padding: 0 20px 20px 20px;
    height: 300px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    justify-content: flex-start;
    overflow-y: scroll;
    scrollbar-width: thin !important;
    scrollbar-color: #A8A8A8 !important;

    &::-webkit-scrollbar {
        width: 8px !important;
    }

    &::-webkit-scrollbar-track {
        background: transparent !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #A8A8A8 !important;
        border-radius: 0.4rem !important;
        border: 3px solid #f0f0f0 !important;
    }
}

@media screen and (max-width: 1024px) {
    .lista-depoimentos {
        overflow-y: none;
        height: auto;
        gap: 20px;
        grid-template-columns: 1fr;
        scrollbar-width: none !important;
        scrollbar-color: transparent !important;

        &::-webkit-scrollbar {
            width: 0 !important;
        }

        .card-depoimento {
            margin: 0;
        }
    }
}

@media screen and (max-width: 480px) {
    .lista-depoimentos {
        .card-depoimento {
            height: 210px;
        }
    }
}