.divider_title {
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
    font-weight: bold !important;
    font-family: "Lato" !important;
    font-size: 0.9rem !important;
    color: #acacac !important;
    margin: 1rem 0 !important;

    &::before {
        content: "" !important;
        flex: 1 !important;
        background-color: #acacac !important;
        height: 1px !important;
        margin-right: 4px !important;
    }

    &::after {
        content: "" !important;
        flex: 1 !important;
        background-color: #acacac !important;
        height: 1px !important;
        margin-left: 4px !important;
    }
}

.label_with_response {
    // font-weight: bold !important;

    span {
        color: #bd0e0e !important;
    }
}

.area_outlined {
    border-radius: 4px !important;
    border: 1px solid #acacac !important;
    padding: 8px !important;

    legend {
        font-family: "Lato" !important;
        font-weight: bold !important;
        color: #2c2c2c !important;
        padding: 0px 6px !important;
    }

    .area_switch {
        display: flex !important;
        justify-content: flex-start !important;
        align-items: center !important;

        h6 {
            font-weight: bold !important;
        }
    }

    &:focus-within {
        border: 1px solid #bd0e0e !important;

        legend {
            color: #bd0e0e !important;
        }
    }
}

.area_outlined.focus_f_five {
    &:focus-within {
        border: 1px solid #001D7E !important;

        legend {
            color: #001D7E !important;
        }
    }
}