.head_response_filter {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 4rem !important;
    text-align: center;

    .title_select_filtro {
        color: #2c2c2c !important;
        font-weight: bold !important;
        font-size: 1.6rem !important;
    }

    .info_filter {
        padding: 0 0.8rem !important;
        margin-bottom: 0.6rem !important;
        font-weight: bold !important;
        color: #484848cc !important;
    }

    .filters_questions_in_respnse {
        padding: 0.4rem !important;
        display: flex !important;
        gap: 0.6rem !important;
    }
}

.nothing {
    width: 100% !important;
    margin-top: 2.8rem !important;

    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;

    .text_no_find {
        font-size: 1.4rem !important;
        font-weight: bold !important;
        color: #2c2c2ccd !important;
    }

    .subtext_no_find {
        font-size: 1rem !important;
        color: #9999997c !important;
    }
}

.questions_area_response {
    display: flex !important;
    flex-direction: column !important;
    padding: 0.6rem !important;
    margin: 0.6rem !important;
    border: 1px solid #ddd !important;
    border-radius: 4px !important;
    
    .question_author {
        display: flex !important;
        justify-content: flex-start !important;
        align-items: center !important;
        gap: 0.4rem !important;

        margin-bottom: 0.6rem !important;

        .author_name_course {
            display: flex !important;
            flex-direction: column !important;

            .author_name {
                font-size: 0.9rem !important;
                font-weight: bold !important;
                text-transform: capitalize !important;
            }

            .author_course {
                font-size: 0.8rem !important;
                color: #2c2c2cc7 !important;
            }
        }
    }

    .question_view {
        padding: 0.6rem !important;

        > h6 {
            font-family: "Roboto" !important;
            font-size: 1rem !important;
            font-weight: 400 !important;
        }
    }

    .question_view_actions {
        display: flex !important;
        justify-content: flex-end !important;
        align-items: center !important;
    }
}

.awaiting {
    margin-top: 36px;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 0.6rem !important;

    h6 {
        font-weight: bold !important;
        color: #999 !important;
    }
}

@media screen and (max-width: 840px) {
    .head_response_filter {
        .title_select_filtro {
            font-size: 1.2rem !important;
            margin-bottom: 1rem !important;
        }
    
        .filters_questions_in_respnse {
            display: flex !important;
            flex-direction: column !important;
            width: 100% !important;
            padding: 0 !important;
        }
    }

    .questions_area_response {
        margin: 0.2rem !important;
        
        .question_author {
            margin-bottom: 0 !important;
    
            .author_name_course {
                .author_name {
                    font-size: 0.8rem !important;
                }
    
                .author_course {
                    font-size: 0.7rem !important;
                }
            }
        }
    
        .question_view {
            padding: 0.4rem !important;
    
            > h6 {
                font-size: 0.8rem !important;
            }
        }
    
        .question_view_actions {
            button {
                font-size: 0.6rem !important;
            }
        }
    }
}